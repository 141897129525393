import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { view_donationAPI } from '../Features/services';
import { Imageurl } from '../Features/apisUrl';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Avatar, IconButton } from '@mui/material';
import { Strings } from '../Common/String';
import nodatafound from '../assets/background/nodatafound.png';
import  PersonIcon from '@mui/icons-material/Person';

function JmDeathList() {
  const [data, setdata] = useState([]);
  const [offset, setoffset] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const view_donationResponce = await view_donationAPI({
        jm_member_id: localStorage.getItem("jm_member"),
        payment_status: 0,
        offset: offset,
      });
      if (view_donationResponce?.ok === true) {
        if (view_donationResponce?.data?.length > 0) {
          setdata(view_donationResponce.data);
        } else {
          setdata([]);
        }
      } else {
        setdata(view_donationResponce);
      }
    })();
  }, [offset]);

  const handleBackClick = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <Box mt={4}>
      <Grid container spacing={3}>
        <Grid item xs={12} mb={6}>
          <Box display="flex" alignItems="center" mb={2}>
          <IconButton onClick={() => navigate(-1)} aria-label="back">
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h5" component="div" sx={{ ml: 2 }}>
              {Strings.JMDeathList}
            </Typography>
          </Box>
          {data && data.length > 0 ? (
            <Grid container spacing={3}>
              {data.map((val) => (
                <Grid item xs={12} md={6} xl={3} key={val.jm_payment_id}>
                  <Card>
                  {val.photo ? (
                      <CardMedia
                        component="img"
                        height="140"
                        image={Imageurl.member_image + val.photo}
                        alt={val.surname}
                      />
                    ) : (
                      <Avatar
                        sx={{ width: 140, height: 140, backgroundColor: 'grey', margin: 'auto' }}
                        alt={val.surname}
                        src={PersonIcon} // This icon will be used as the default profile picture
                      />
                    )}
                    <CardContent>
                      <Typography variant="h5" component="div">
                        {val.surname + " " + val.first_name + " " + val.last_name}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {val.village_name}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {val.contact}
                      </Typography>
                      <Box mt={2}>
                        <Button variant="contained" color="primary">
                          {Strings.PayNow}
                        </Button>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              p={3}
            >
              <Card sx={{ backgroundColor: '#f4f4f4', textAlign: 'center', width: '100%' }}>
                <Box p={3}>
                <img src={nodatafound} alt="No Data Found" style={{ width: '500px', height: 'auto' }} />
                </Box>
              </Card>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default JmDeathList;
