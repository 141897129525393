import { useState, useEffect } from "react";
import {
  Card,
  Box,
  Typography,
  Avatar,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { View_donation_listAPI } from "../Features/services";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import { Imageurl } from "../Features/apisUrl";
import { Strings } from "../Common/String";
import nodatafound from "../assets/background/nodatafound.png";
import PersonIcon from "@mui/icons-material/Person";

function DonationList() {
  const theme = useTheme();
  const navigate = useNavigate();
  const formatDate1 = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const [columns] = useState([
    { name: Strings.Photo, align: "left" },
    { name: Strings.Name, align: "left" },
    { name: Strings.Date, align: "left" },
    { name: Strings.Category, align: "left" },
    { name: Strings.Amount, align: "left" },
    { name: Strings.Contact, align: "left" },
    { name: Strings.Native, align: "left" },
  ]);

  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [open, setOpen] = useState(false);

  const [selectedMember, setSelectedMember] = useState(null);
  const handleOpen = (index,datas) => {
    if(datas?.photo) {
      setSelectedMember(rows[index]);
      setOpen(true);
    }
  }

  const handleClose = () => setOpen(false);

  useEffect(() => {
    (async () => {
      try {
        const JmMemberResponse = await View_donation_listAPI({
          offset: page * rowsPerPage,
          contact: "",
          village_id: "",
          donation_category: "",
          user_id: localStorage.getItem("family_member_id"),
        });
        if (JmMemberResponse.data) {
          setRows(JmMemberResponse.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    })();
  }, [
    page,
    rowsPerPage,
    theme.palette.common.white,
    theme.palette.secondary.main,
  ]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const defaultProfileImage = <PersonIcon style={{ fontSize: 30}} />;

  return (
    <Box py={3}>
      <Box mb={3}>
        <Box
          display="flex"
          alignItems="center"
          p={2}
          sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}
        >
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBack />
          </IconButton>
          <Typography variant="h6" ml={1}>
            {Strings.MyPreviousDonation}
          </Typography>
        </Box>
        <Card>
          <Box
            sx={{
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: `1px solid ${theme.palette.divider}`,
                },
              },
            }}
          >
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column.name} align={column.align}>
                        <Typography>{column.name}</Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {rows && rows.length > 0 ? (
                  <TableBody>
                    {rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => (
                        <TableRow key={index}>
                          <TableCell align="center" sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Tooltip
                              title={row.first_name}
                              placeholder="bottom"
                            >
                              <Avatar
                                src={Imageurl.member_image + row.photo}
                                alt={row.first_name}
                                sx={{
                                  cursor: "pointer",
                                  "&:hover, &:focus": {
                                    zIndex: "10",
                                  },
                                }}
                                onClick={() => handleOpen(index, row)}
                              >
                                {defaultProfileImage}
                              </Avatar>
                            </Tooltip>
                          </TableCell>
                          <TableCell align="center">{row.first_name == "" || row.first_name == null ? '-' : row.first_name}</TableCell>
                          <TableCell align="center">
                            {row.date == "" || row.date == null ? '-' : formatDate1(row.date)}
                          </TableCell>
                          <TableCell align="center">
                            {row.category_name == "" || row.category_name == null ? '-' : row.category_name}
                          </TableCell>
                          <TableCell align="center">{row.amount == "" || row.amount == null ? '-' : row.amount}</TableCell>
                          <TableCell align="center">
                            {row.gender == "female" ? "-" : row.contact == "" || row.contact == null ? '-' : row.contact}
                          </TableCell>
                          <TableCell align="center">{row.village_name == "" || row.village_name == null ? '-' : row.village_name}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={columns.length}>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <img
                            src={nodatafound}
                            alt="No Data Found"
                            style={{ width: "500px", height: "auto" }}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
              <TablePagination
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </Box>
        </Card>

        {selectedMember && selectedMember.photo && (
        <Dialog open={open} onClose={handleClose}>
          <DialogContent>
            {selectedMember && selectedMember.photo ? (
              <img
                src={Imageurl.member_image + selectedMember.photo}
                alt="Member's Photo"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            ) : (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <PersonIcon style={{ fontSize: 80, color: "gray" }} />
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
      </Box>
    </Box>
  );
}

export default DonationList;
