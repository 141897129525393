import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { ViewCommitteeMembersAPI } from "../Features/services";
import { Strings } from "../Common/String";
import nodatafound from "../assets/background/nodatafound.png";

function Committeelist() {
  const navigate = useNavigate();
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ViewCommitteeMembersAPI({ offset });
        if (response) {
          setData(response || []);
        } else {
          setData([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setData([]);
      }
    };

    fetchData();
  }, [offset]);

  const handleCardClick = (selectedData) => {
    navigate(`/committeememberlist`, {
      state: { data: selectedData },
    });
  };

  return (
    <Box py={3}>
      <Grid container spacing={3}>
        {data && data.length > 0 ? (
          data.map((val) => (
            <Grid
              item
              xs={12}
              sm={6}
              xl={3}
              key={val.committee_pragatimandal_id}
            >
              <Card
                onClick={() => handleCardClick(val)}
                sx={{
                  cursor: "pointer",
                  minHeight: "100%",
                  backgroundColor: "transparent",
                  boxShadow:
                    "0px 1px 20px 0px rgba(0,0,0,0.12),0px 3px 3px 0px rgba(0,0,0,0.12),0px 1px 3px 0px rgba(0,0,0,0.12)",
                }}
              >
                <CardContent>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ color: "darkred" }}
                  >
                    {val.committee_pragatimandal_name}
                  </Typography>
                  <Box display="flex" alignItems="center">
                    <Typography variant="body2" color="text.secondary">
                      {Strings.ClickForMore + val.committee_pragatimandal_name}
                    </Typography>
                    <IconButton
                      aria-label="view details"
                      onClick={() => handleCardClick(val)}
                    >
                      <ArrowForwardIcon />
                    </IconButton>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
            width="100%"
            p={3}
          >
            <img
              src={nodatafound}
              alt="No Data Found"
              style={{ width: "500px", height: "auto" }}
            />
          </Box>
        )}
      </Grid>
    </Box>
  );
}

export default Committeelist;
