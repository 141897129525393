import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Avatar,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  View_donation_listAPI,
} from "../Features/services";
import { Strings } from "../Common/String";
import nodatafound from "../assets/background/nodatafound.png";
import { Imageurl } from "../Features/apisUrl";
import PersonIcon from "@mui/icons-material/Person";

function DonerList() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage]);

  const fetchData = async () => {
    try {
      const recent_donorsResponse = await View_donation_listAPI({
        offset: page * rowsPerPage,
        contact: "",
        village_id: "",
        donation_category: "",
        user_id: "",
      });
      if (recent_donorsResponse?.data?.length > 0) {
        setData(recent_donorsResponse?.data || []);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setData([]);
    } finally {
      setLoading(false);
    }
  };

  // const handleCardClick = (donorId) => {
  //   navigate(`/donationdonors/${donorId}`);
  // };

  const handleImageClick = (index,image) => {
    if(image?.photo) {
      setSelectedImage(image?.photo);
      setOpen(true);
    }

  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const formatDate1 = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <Typography variant="h6" color="textSecondary">
          Loading...
        </Typography>
      </Box>
    );
  }

  const defaultProfileImage = <PersonIcon style={{ fontSize: 30 }} />;

  return (
    <Box py={3}>
      <Box mb={6}>
        <Box display="flex" alignItems="center">
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
          <Typography
            sx={{
              fontSize: "x-large",
              color: "#777",
              fontWeight: 500,
              alignItems: "center",
              display: "flex",
            }}
          >
            {Strings.DonationDonorsList}
          </Typography>
        </Box>
        <br />
        <Card>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{ fontWeight: 600 }}>
                    {Strings.Image}
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: 600 }}>
                    {Strings.DonorName}
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: 600 }}>
                    {Strings.Village}
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: 600 }}>
                    {Strings.Contact}
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: 600 }}>
                    {Strings.Category}
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: 600 }}>
                    {Strings.Date}
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: 600 }}>
                    {Strings.Donation}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.length > 0 ? (
                  data
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((donor, index) => (
                      <TableRow key={donor.donor_id}>
                        <TableCell align="center" sx={{ display: 'flex', justifyContent: 'center' }}>
                          <Avatar
                            src={Imageurl.member_image + donor.photo}
                            alt={donor.first_name}
                            sx={{
                              cursor: "pointer",
                              "&:hover, &:focus": {
                                zIndex: "10",
                              },
                            }}
                            onClick={() => handleImageClick(index, donor)}
                          >
                            {defaultProfileImage}
                          </Avatar>
                        </TableCell>
                        <TableCell align="center">
                          {donor.surname == "" || donor.surname == null ? '-' : donor.surname +
                            " " +
                            donor.first_name == "" || donor.first_name == null ? '-' : donor.first_name +
                            " " +
                            donor.last_name == "" || donor.last_name == null ? '-' : donor.last_name}
                        </TableCell>
                        <TableCell align="center">
                          {donor.village_name == "" || donor.village_name == null ? '-' : donor.village_name}
                        </TableCell>
                        <TableCell align="center">
                          {donor.gender === "female" ? "-" : donor.contact == "" || donor.contact == null ? '-' : donor.contact}
                        </TableCell>
                        <TableCell align="center">
                          {donor.category_name == "" || donor.category_name == null ? '-' : donor.category_name}
                        </TableCell>
                        <TableCell align="center">
                          {donor.date == "" || donor.date == null ? '-' : formatDate1(donor.date)}
                        </TableCell>
                        <TableCell align="center">{donor.amount == "" || donor.amount == null ? '-' : donor.amount}</TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        p={3}
                      >
                        <img
                          src={nodatafound}
                          alt="No Data Found"
                          style={{ width: "500px", height: "auto" }}
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Box>
      <Dialog open={open} onClose={handleCloseModal}>
        <DialogContent>
          <img
            src={Imageurl.member_image + selectedImage}
            alt="Donor Photo"
            style={{ width: "100%", height: "auto" }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default DonerList;
