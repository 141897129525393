import React, { useEffect, useRef, useState } from "react";
import "../styles/WelcomePage.scss";
import Footer from "./Footer";
import Header from "./Header";
import { BesanuAPI, NewsAPI, SliderAPI } from "../Features/services";
import NewsCard from "../CustomComponent/NewsCard";
import DeadCard from "../CustomComponent/DeadCard";
import { Strings } from "../Common/String";
import { makeStyles } from '@material-ui/core/styles';
import Carousel from 'react-material-ui-carousel';
import { Paper } from '@material-ui/core';
import { Imageurl } from "../Features/apisUrl";
import Slider from "./Slider";
import DashboardContact from "./DashboardContact";

const useStyles = makeStyles({
  carouselItem: {
    height: 600,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  carouselImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
});

const WelcomePage = () => {
  const classes = useStyles();
  const [data, setdata] = useState([]);
  const [sliderData, setSliderData] = useState([]);
  const [besnuData, setBesnuData] = useState([]);
  const [offset, setoffset] = useState(0);

  useEffect(() => {
    (async () => {
      const CategoryResponce = await NewsAPI({
        offset: offset,
      });
      if (CategoryResponce?.ok === true) {
        if (CategoryResponce?.data?.length > 0) {
          setdata(CategoryResponce.data);
        } else {
          setdata([]);
        }
      } else {
        setdata(CategoryResponce);
      }
    })();
  }, [offset]);

  useEffect(() => {
    (async () => {
      const CategoryResponce = await SliderAPI({
        offset: offset,
      });
      if (CategoryResponce) {
        if (CategoryResponce?.length > 0) {
          setSliderData(CategoryResponce);
        } else {
          setSliderData([]);
        }
      } else {
        setSliderData(CategoryResponce);
      }
    })();
  }, [offset]);

  useEffect(() => {
    (async () => {
      const CategoryResponce = await BesanuAPI({
        offset: offset,
      });
      if (CategoryResponce?.ok === true) {
        if (CategoryResponce?.data?.length > 0) {
          setBesnuData(CategoryResponce.data);
        } else {
          setBesnuData([]);
        }
      } else {
        setBesnuData(CategoryResponce);
      }
    })();
  }, [offset]);

  return (
    <div>
      <Header />
      <div className="welcome-page">
        <div>
      {/* <Carousel>
          {sliderData.map((slide) => (
            <Paper key={slide.id} className={classes.carouselItem}>
              <img src={Imageurl.slider_images + slide.slider_image} className={classes.carouselImage} />
            </Paper>
          ))}
        </Carousel> */}
        <Slider /> 
        {data?.length > 0 || besnuData?.length > 0 ? 
        <div className="content">
          <div className="column">
          <h2>{data?.length > 0 ? Strings.Events : ""}</h2>
            <div className="events-container">
              {data && data.map((newsItem) => (
                <NewsCard key={newsItem.news_id} news={newsItem} />
              ))}
            </div>
          </div>
          <div className="column">
          <h2>{besnuData?.length > 0 ? Strings.NotableDeaths : ""}</h2>
            <div className="deaths-container">
              {besnuData && besnuData.map((datas) => (
                <DeadCard key={datas.news_id} besnu={datas} />
              ))}
            </div>
          </div>
        </div>
        : '' }
        {/* <Slider /> */}
        <div>
        <DashboardContact />
        </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default WelcomePage;
