import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import { view_donationAPI } from '../Features/services';
import { Strings } from '../Common/String';
import nodatafound from '../assets/background/nodatafound.png';
import { TablePagination } from '@mui/material';

function PaidHistory() {
  const [rows, setRows] = useState([]);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const view_donationResponse = await view_donationAPI({
          jm_member_id: localStorage.getItem('jm_member'),
          payment_status: 1,
          offset: offset,
        });
        if (view_donationResponse.data) {
          setRows(view_donationResponse.data);
        } else {
          setRows([]);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [offset]);

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box py={3}>
      <Box mb={3} display="flex" alignItems="center">
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6" component="div" style={{ marginLeft: '10px' }}>
          {Strings.PaidHistory}
        </Typography>
      </Box>
      <Card style={{ marginBottom: '30px' }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">{Strings.Photo}</TableCell>
                <TableCell align="center">{Strings.Name}</TableCell>
                <TableCell align="center">{Strings.Village}</TableCell>
                <TableCell align="center">{Strings.Contact}</TableCell>
                <TableCell align="center">{Strings.Action}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows && rows.length > 0 ? (
                rows.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">
                      <Avatar alt={row.first_name} src={row.photo} />
                    </TableCell>
                    <TableCell align="center">{row.first_name == "" || row.first_name == null ? '-' : row.first_name}</TableCell>
                    <TableCell align="center">{row.village_name == "" || row.village_name == null ? '-' : row.village_name}</TableCell>
                    <TableCell align="center">{row.gender == "female" ? "-" : row.contact == "" || row.contact == null ? '-' : row.contact}</TableCell>
                    <TableCell align="center"><Chip label="Receipt" variant="outlined" color="secondary" size="small" /></TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      p={3}
                    >
                      <Box p={3}>
                      <img src={nodatafound} alt="No Data Found" style={{ width: '500px', height: 'auto' }} />
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      </Card>
    </Box>
  );
}

export default PaidHistory;
