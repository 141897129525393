import React, { useState, useEffect } from "react";
import {
  Drawer,
  List,
  Divider,
  IconButton,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme,
  Box,
  Toolbar,
  AppBar,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { NavLink, useLocation } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import NewsIcon from "@mui/icons-material/Announcement";
import EventNoteIcon from "@mui/icons-material/EventNote";
import BusinessIcon from "@mui/icons-material/Business";
import MyBusinessIcon from "@mui/icons-material/Store";
import ManageBusinessIcon from "@mui/icons-material/Settings";
import MarriageIcon from "@mui/icons-material/Favorite";
import BloodDonorsIcon from "@mui/icons-material/Bloodtype";
import MemberRegistrationIcon from "@mui/icons-material/PersonAdd";
import SearchIcon from "@mui/icons-material/Search";
import FamilyMembersIcon from "@mui/icons-material/People";
import CommitteeListIcon from "@mui/icons-material/List";
import AppDonorsIcon from "@mui/icons-material/Support";
import ProfileIcon from "@mui/icons-material/AccountCircle";
import HistoryIcon from "@mui/icons-material/History";
import PhotoGalleryIcon from "@mui/icons-material/Photo";
import VideoGalleryIcon from "@mui/icons-material/Videocam";
import QueryIcon from "@mui/icons-material/QuestionAnswer";
import ContactUsIcon from "@mui/icons-material/ContactMail";
import { Imageurl } from "../Features/apisUrl";
import '../styles/Sidebar.scss';

const drawerWidth = 241;

const collapseItem = (active) => ({
  backgroundColor: active ? "#2196F3" : "#FFFFFF",
  color: active ? "#FFFFFF" : "#000000",
  display: "flex",
  alignItems: "center",
  width: "100%",
  padding: "10px 16px",
  borderRadius: "0px",
  cursor: "pointer",
  userSelect: "none",
  boxShadow: active ? "0 4px 20px rgba(0, 0, 0, 0.1)" : "none",
  transition: "background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease",
  "&:hover": {
    backgroundColor: "#5ab3fa", // Light blue color on hover
  },
});

const collapseIconBox = (active) => ({
  backgroundColor: active ? "#FFFFFF" : "#EEEEEE",
  minWidth: "32px",
  minHeight: "32px",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.05)",
  transition: "background-color 0.3s ease",
});

const collapseIcon = (active) => ({
  color: active ? "#2196F3" : "#9E9E9E",
});

const collapseText = (active) => ({
  marginLeft: "12px",
  fontWeight: active ? 500 : 400,
  fontSize: "0.275rem !important",
  color: active ? "#FFFFFF" : "#000000",
  transition: "color 0.3s ease, margin 0.3s ease",
});

const Sidebar = ({ open, handleDrawerClose, condition }) => {
  const [activeItem, setActiveItem] = useState("/dashboard");
  const theme = useTheme();
  const location = useLocation();

  useEffect(() => {
    setActiveItem(window.location.pathname);
  }, [location.pathname]);

  const FullNames =
    "Hi, " + localStorage.getItem("first_name") + " " + localStorage.getItem("surname");
  const profileImage = localStorage.getItem("photo");
  const profileImageUrl = profileImage
    ? Imageurl.member_image + profileImage
    : null;
  const approve_status = localStorage.getItem("approve_status");

  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={open}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        overflowY: 'hidden',
        whiteSpace: 'nowrap',
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          // borderTopRightRadius: "16px",
          // borderBottomRightRadius: "16px",
          backgroundColor: "#FAF9F6",
          display: "flex",
          flexDirection: "column",
          transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <AppBar
          position="static"
          color="default"
          elevation={0}
          sx={{
            borderBottom: "1px solid #DDDDDD",
            boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
            backgroundColor: "#FAF9F6",
            // borderTopRightRadius: "16px",
          }}
        >
          <Toolbar sx={{ minHeight: 56, display: "flex", alignItems: "center", paddingLeft: 2 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="body1" sx={{ fontWeight: 600 }}>
                {FullNames}
              </Typography>
            </Box>
            <IconButton onClick={handleDrawerClose} className="icon-button-custom">
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </Toolbar>
        </AppBar>
        <Divider />
          <List
            sx={{
              flexGrow: 1,
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                display: "none",
              },
              "-ms-overflow-style": "none",
              scrollbarWidth: "none",
            }}
          >
            <ListItem
              button
              component={NavLink}
              to="/dashboard"
              onClick={() => setActiveItem("/dashboard")}
              sx={collapseItem(activeItem === "/dashboard")}
            >
              <ListItemIcon sx={collapseIconBox(activeItem === "/dashboard")}>
                <DashboardIcon sx={collapseIcon(activeItem === "/dashboard")} />
              </ListItemIcon>
              <ListItemText
                primary="Dashboard"
                className="sidebarFont"
                sx={collapseText(activeItem === "/dashboard")}
              />
            </ListItem>
            <ListItem
              button
              component={NavLink}
              to="/newsscreen"
              onClick={() => setActiveItem("/newsscreen")}
              sx={collapseItem(activeItem === "/newsscreen")}
            >
              <ListItemIcon sx={collapseIconBox(activeItem === "/newsscreen")}>
                <NewsIcon sx={collapseIcon(activeItem === "/newsscreen")} />
              </ListItemIcon>
              <ListItemText
                primary="News"
                 className="sidebarFont"
                sx={collapseText(activeItem === "/newsscreen")}
              />
            </ListItem>
            <ListItem
              button
              component={NavLink}
              to="/besnu"
              onClick={() => setActiveItem("/besnu")}
              sx={collapseItem(activeItem === "/besnu")}
            >
              <ListItemIcon sx={collapseIconBox(activeItem === "/besnu")}>
                <EventNoteIcon sx={collapseIcon(activeItem === "/besnu")} />
              </ListItemIcon>
              <ListItemText
                primary="Besnu"
                sx={collapseText(activeItem === "/besnu")}
              />
            </ListItem>
            <ListItem
              button
              component={NavLink}
              to="/business"
              onClick={() => setActiveItem("/business")}
              sx={collapseItem(activeItem === "/business")}
            >
              <ListItemIcon sx={collapseIconBox(activeItem === "/business")}>
                <BusinessIcon sx={collapseIcon(activeItem === "/business")} />
              </ListItemIcon>
              <ListItemText
                primary="Business"
                sx={collapseText(activeItem === "/business")}
              />
            </ListItem>
            <ListItem
              button
              component={NavLink}
              to="/mybusiness"
              onClick={() => setActiveItem("/mybusiness")}
              sx={collapseItem(activeItem === "/mybusiness")}
            >
              <ListItemIcon sx={collapseIconBox(activeItem === "/mybusiness")}>
                <MyBusinessIcon sx={collapseIcon(activeItem === "/mybusiness")} />
              </ListItemIcon>
              <ListItemText
                primary="My Business"
                sx={collapseText(activeItem === "/mybusiness")}
              />
            </ListItem>
            <ListItem
              button
              component={NavLink}
              to="/managebusiness"
              onClick={() => setActiveItem("/managebusiness")}
              sx={collapseItem(activeItem === "/managebusiness")}
            >
              <ListItemIcon sx={collapseIconBox(activeItem === "/managebusiness")}>
                <ManageBusinessIcon sx={collapseIcon(activeItem === "/managebusiness")} />
              </ListItemIcon>
              <ListItemText
                primary="Manage Business"
                sx={collapseText(activeItem === "/managebusiness")}
              />
            </ListItem>
            <ListItem
              button
              component={NavLink}
              to="/marriage"
              onClick={() => setActiveItem("/marriage")}
              sx={collapseItem(activeItem === "/marriage")}
            >
              <ListItemIcon sx={collapseIconBox(activeItem === "/marriage")}>
                <MarriageIcon sx={collapseIcon(activeItem === "/marriage")} />
              </ListItemIcon>
              <ListItemText
                primary="Marriage"
                sx={collapseText(activeItem === "/marriage")}
              />
            </ListItem>
            <ListItem
              button
              component={NavLink}
              to="/blooddonors"
              onClick={() => setActiveItem("/blooddonors")}
              sx={collapseItem(activeItem === "/blooddonors")}
            >
              <ListItemIcon sx={collapseIconBox(activeItem === "/blooddonors")}>
                <BloodDonorsIcon sx={collapseIcon(activeItem === "/blooddonors")} />
              </ListItemIcon>
              <ListItemText
                primary="Blood Donors"
                sx={collapseText(activeItem === "/blooddonors")}
              />
            </ListItem>
            <ListItem
              button
              component={NavLink}
              to="/memberregistration"
              onClick={() => setActiveItem("/memberregistration")}
              sx={collapseItem(activeItem === "/memberregistration")}
            >
              <ListItemIcon sx={collapseIconBox(activeItem === "/memberregistration")}>
                <MemberRegistrationIcon sx={collapseIcon(activeItem === "/memberregistration")} />
              </ListItemIcon>
              <ListItemText
                primary="Member Registration"
                sx={collapseText(activeItem === "/memberregistration")}
              />
            </ListItem>
            <ListItem
              button
              component={NavLink}
              to="/search"
              onClick={() => setActiveItem("/search")}
              sx={collapseItem(activeItem === "/search")}
            >
              <ListItemIcon sx={collapseIconBox(activeItem === "/search")}>
                <SearchIcon sx={collapseIcon(activeItem === "/search")} />
              </ListItemIcon>
              <ListItemText
                primary="Search"
                sx={collapseText(activeItem === "/search")}
              />
            </ListItem>
            <ListItem
              button
              component={NavLink}
              to="/familymembers"
              onClick={() => setActiveItem("/familymembers")}
              sx={collapseItem(activeItem === "/familymembers")}
            >
              <ListItemIcon sx={collapseIconBox(activeItem === "/familymembers")}>
                <FamilyMembersIcon sx={collapseIcon(activeItem === "/familymembers")} />
              </ListItemIcon>
              <ListItemText
                primary="Family Members"
                sx={collapseText(activeItem === "/familymembers")}
              />
            </ListItem>
            <ListItem
              button
              component={NavLink}
              to="/committeelist"
              onClick={() => setActiveItem("/committeelist")}
              sx={collapseItem(activeItem === "/committeelist")}
            >
              <ListItemIcon sx={collapseIconBox(activeItem === "/committeelist")}>
                <CommitteeListIcon sx={collapseIcon(activeItem === "/committeelist")} />
              </ListItemIcon>
              <ListItemText
                primary="Committee List"
                sx={collapseText(activeItem === "/committeelist")}
              />
            </ListItem>
            <ListItem
              button
              component={NavLink}
              to="/appdonors"
              onClick={() => setActiveItem("/appdonors")}
              sx={collapseItem(activeItem === "/appdonors")}
            >
              <ListItemIcon sx={collapseIconBox(activeItem === "/appdonors")}>
                <AppDonorsIcon sx={collapseIcon(activeItem === "/appdonors")} />
              </ListItemIcon>
              <ListItemText
                primary="App Donors"
                sx={collapseText(activeItem === "/appdonors")}
              />
            </ListItem>
            <ListItem
              button
              component={NavLink}
              to="/profile"
              onClick={() => setActiveItem("/profile")}
              sx={collapseItem(activeItem === "/profile")}
            >
              <ListItemIcon sx={collapseIconBox(activeItem === "/profile")}>
                <ProfileIcon sx={collapseIcon(activeItem === "/profile")} />
              </ListItemIcon>
              <ListItemText
                primary="Profile"
                sx={collapseText(activeItem === "/profile")}
              />
            </ListItem>
            <ListItem
              button
              component={NavLink}
              to="/history"
              onClick={() => setActiveItem("/history")}
              sx={collapseItem(activeItem === "/history")}
            >
              <ListItemIcon sx={collapseIconBox(activeItem === "/history")}>
                <HistoryIcon sx={collapseIcon(activeItem === "/history")} />
              </ListItemIcon>
              <ListItemText
                primary="History"
                sx={collapseText(activeItem === "/history")}
              />
            </ListItem>
            <ListItem
              button
              component={NavLink}
              to="/photogallery"
              onClick={() => setActiveItem("/photogallery")}
              sx={collapseItem(activeItem === "/photogallery")}
            >
              <ListItemIcon sx={collapseIconBox(activeItem === "/photogallery")}>
                <PhotoGalleryIcon sx={collapseIcon(activeItem === "/photogallery")} />
              </ListItemIcon>
              <ListItemText
                primary="Photo Gallery"
                sx={collapseText(activeItem === "/photogallery")}
              />
            </ListItem>
            <ListItem
              button
              component={NavLink}
              to="/videogallery"
              onClick={() => setActiveItem("/videogallery")}
              sx={collapseItem(activeItem === "/videogallery")}
            >
              <ListItemIcon sx={collapseIconBox(activeItem === "/videogallery")}>
                <VideoGalleryIcon sx={collapseIcon(activeItem === "/videogallery")} />
              </ListItemIcon>
              <ListItemText
                primary="Video Gallery"
                sx={collapseText(activeItem === "/videogallery")}
              />
            </ListItem>
            <ListItem
              button
              component={NavLink}
              to="/query"
              onClick={() => setActiveItem("/query")}
              sx={collapseItem(activeItem === "/query")}
            >
              <ListItemIcon sx={collapseIconBox(activeItem === "/query")}>
                <QueryIcon sx={collapseIcon(activeItem === "/query")} />
              </ListItemIcon>
              <ListItemText
                primary="Query"
                sx={collapseText(activeItem === "/query")}
              />
            </ListItem>
            <ListItem
              button
              component={NavLink}
              to="/contactus"
              onClick={() => setActiveItem("/contactus")}
              sx={collapseItem(activeItem === "/contactus")}
            >
              <ListItemIcon sx={collapseIconBox(activeItem === "/contactus")}>
                <ContactUsIcon sx={collapseIcon(activeItem === "/contactus")} />
              </ListItemIcon>
              <ListItemText
                primary="Contact Us"
                sx={collapseText(activeItem === "/contactus")}
              />
            </ListItem>
          </List>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
