import React from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import {
  Card,
  Typography,
  Box,
  Grid,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import {
  Facebook as FacebookIcon,
  YouTube as YouTubeIcon,
  Instagram as InstagramIcon,
  Telegram as TelegramIcon,
  LinkedIn as LinkedInIcon,
  Description as DescriptionIcon,
  LocationOn as LocationOnIcon,
  PinDrop as PinDropIcon,
  Language as LanguageIcon,
  BusinessCenter as BusinessCenterIcon,
  AccountCircle as AccountCircleIcon,
  ArrowBack as ArrowBackIcon,
  Email as EmailIcon,
} from "@mui/icons-material";
import { Imageurl } from "../Features/apisUrl";
import { Strings } from "../Common/String";
import "../styles/BusinessDetails.scss";

function BusinessDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const businessDetails = location.state?.business || null;
  const isMobile = useMediaQuery('(max-width:600px)');
  const isTablet = useMediaQuery('(max-width:900px)');

  const getIconColor = (category) => {
    switch (category) {
      case Strings.category:
        return "#ff9800"; // Orange
      case Strings.description:
        return "#4caf50"; // Green
      case Strings.address:
        return "#2196f3"; // Blue
      case Strings.city:
        return "#f44336"; // Red
      case Strings.PIN:
        return "#9c27b0"; // Purple
      case Strings.country:
        return "#673ab7"; // Deep Purple
      case Strings.contact_number1:
        return "#e91e63"; // Pink
      case Strings.contact_number2:
        return "#00bcd4"; // Cyan
      case Strings.website:
        return "#009688"; // Teal
      case Strings.email:
        return "#ff5722"; // Deep Orange
      case Strings.business_catalogue:
        return "#795548"; // Brown
      case Strings.concern_person:
        return "#607d8b"; // Blue Grey
      case Strings.contact:
        return "#ffc107"; // Amber
      case Strings.map_link:
        return "#795548"; // Brown
      default:
        return "inherit"; // Default color
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const defaultProfileImage = <AccountCircleIcon style={{ fontSize: isMobile ? 150 : 300 }} />;

  return (
    <Box className="business-details-container">
      <Box className="back-button">
        <IconButton onClick={handleBack}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6" style={{ fontWeight: 500 }}>
          {Strings.BusinessDetailOf} {businessDetails ? businessDetails.business_name : ""}
        </Typography>
      </Box>
      <Card className={`business-details-card ${isTablet ? 'tablet-view' : ''}`}>
        <Box p={2}>
          {businessDetails ? (
            <Grid container spacing={isMobile ? 2 : 3}>
              <Grid item xs={12} md={4}>
                <Box display="flex" justifyContent="center">
                  {businessDetails.image_1 ? (
                    <img
                      src={Imageurl.business_image + businessDetails.image_1}
                      alt="Business Photo"
                      className="business-image"
                    />
                  ) : (
                    defaultProfileImage
                  )}
                </Box>
                <Box mt={2}>
                  <Typography variant="h5" align="center" style={{ fontWeight: 500 }}>
                    {businessDetails.business_name}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={8}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Grid container spacing={1}>
                      <Grid item>
                        <BusinessCenterIcon style={{ color: getIconColor(Strings.category) }} />
                      </Grid>
                      <Grid item xs={9}>
                        <Typography variant="subtitle1" style={{ fontSize: isMobile ? '0.875rem' : '1rem' }} component="div">
                          <strong>{Strings.category}</strong>
                        </Typography>
                        <Typography variant="body2" component="div">
                          {businessDetails.category_name == "" || businessDetails.category_name == null ? '-' : businessDetails.category_name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Grid container spacing={1}>
                      <Grid item>
                        <DescriptionIcon style={{ color: getIconColor(Strings.description) }} />
                      </Grid>
                      <Grid item xs={9}>
                        <Typography variant="subtitle1" style={{ fontSize: isMobile ? '0.875rem' : '1rem' }} component="div">
                          <strong>{Strings.description}</strong>
                        </Typography>
                        <Typography variant="body2" component="div">
                          {businessDetails.description == "" || businessDetails.description == null ? '-' : businessDetails.description}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Grid container spacing={1}>
                      <Grid item>
                        <AccountCircleIcon style={{ color: getIconColor(Strings.concern_person) }} />
                      </Grid>
                      <Grid item xs={9}>
                        <Typography variant="subtitle1" style={{ fontSize: isMobile ? '0.875rem' : '1rem' }} component="div">
                          <strong>{Strings.concern_person}</strong>
                        </Typography>
                        <Typography variant="body2" component="div">
                          {businessDetails.concern_person == "" || businessDetails.concern_person == null ? '-' : businessDetails.concern_person}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Grid container spacing={1}>
                      <Grid item>
                        <LocationOnIcon style={{ color: getIconColor(Strings.address) }} />
                      </Grid>
                      <Grid item xs={9}>
                        <Typography variant="subtitle1" style={{ fontSize: isMobile ? '0.875rem' : '1rem' }} component="div">
                          <strong>{Strings.address}</strong>
                        </Typography>
                        <Typography variant="body2" component="div">
                          {businessDetails.address == "" || businessDetails.address == null ? '-' : businessDetails.address}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Grid container spacing={1}>
                      <Grid item>
                        <PinDropIcon style={{ color: getIconColor(Strings.city) }} />
                      </Grid>
                      <Grid item xs={9}>
                        <Typography variant="subtitle1" style={{ fontSize: isMobile ? '0.875rem' : '1rem' }} component="div">
                          <strong>{Strings.city}</strong>
                        </Typography>
                        <Typography variant="body2" component="div">
                          {businessDetails.city_name  == "" || businessDetails.city_name == null ? '-' : businessDetails.city_name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Grid container spacing={1}>
                      <Grid item>
                        <PinDropIcon style={{ color: getIconColor(Strings.PIN) }} />
                      </Grid>
                      <Grid item xs={9}>
                        <Typography variant="subtitle1" style={{ fontSize: isMobile ? '0.875rem' : '1rem' }} component="div">
                          <strong>{Strings.PIN}</strong>
                        </Typography>
                        <Typography variant="body2" component="div">
                          {businessDetails.pin  == "" || businessDetails.pin == null ? '-' : businessDetails.pin}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Grid container spacing={1}>
                      <Grid item>
                        <LanguageIcon style={{ color: getIconColor(Strings.country) }} />
                      </Grid>
                      <Grid item xs={9}>
                        <Typography variant="subtitle1" style={{ fontSize: isMobile ? '0.875rem' : '1rem' }} component="div">
                          <strong>{Strings.country}</strong>
                        </Typography>
                        <Typography variant="body2" component="div">
                          {businessDetails.country_name == "" || businessDetails.country_name == null ? '-' : businessDetails.country_name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* New fields */}
                  <Grid item xs={12} sm={6}>
                    <Grid container spacing={1}>
                      <Grid item>
                        <EmailIcon style={{ color: getIconColor(Strings.email) }} />
                      </Grid>
                      <Grid item xs={9}>
                        <Typography variant="subtitle1" style={{ fontSize: isMobile ? '0.875rem' : '1rem' }} component="div">
                          <strong>{Strings.email}</strong>
                        </Typography>
                        <Typography variant="body2" component="div">
                          {businessDetails.email == "" || businessDetails.email == null ? '-' : businessDetails.email}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Grid container spacing={1}>
                      <Grid item>
                        <BusinessCenterIcon style={{ color: getIconColor(Strings.contact) }} />
                      </Grid>
                      <Grid item xs={9}>
                        <Typography variant="subtitle1" style={{ fontSize: isMobile ? '0.875rem' : '1rem' }} component="div">
                          <strong>{Strings.Contact}</strong>
                        </Typography>
                        <Typography variant="body2" component="div">
                          {businessDetails.contact_1 == "" || businessDetails.contact_1 == null ? '-' : businessDetails.contact_1}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Grid container spacing={1}>
                      <Grid item>
                        <BusinessCenterIcon style={{ color: getIconColor(Strings.contact) }} />
                      </Grid>
                      <Grid item xs={9}>
                        <Typography variant="subtitle1" style={{ fontSize: isMobile ? '0.875rem' : '1rem' }} component="div">
                          <strong>{Strings.Contact}</strong>
                        </Typography>
                        <Typography variant="body2" component="div">
                          {businessDetails.contact_2 == "" || businessDetails.contact_2 == null ? '-' : businessDetails.contact_2}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Grid container spacing={1}>
                      <Grid item>
                        <LanguageIcon style={{ color: getIconColor(Strings.website) }} />
                      </Grid>
                      <Grid item xs={9}>
                        <Typography variant="subtitle1" style={{ fontSize: isMobile ? '0.875rem' : '1rem' }} component="div">
                          <strong>{Strings.website}</strong>
                        </Typography>
                        <Typography variant="body2" component="div">
                          {businessDetails.location == "" || businessDetails.location == null ? '-' : businessDetails.location}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Grid container spacing={1}>
                      <Grid item>
                        <DescriptionIcon style={{ color: getIconColor(Strings.business_catalogue) }} />
                      </Grid>
                      <Grid item xs={9}>
                        <Typography variant="subtitle1" style={{ fontSize: isMobile ? '0.875rem' : '1rem' }} component="div">
                          <strong>{Strings.business_catalogue}</strong>
                        </Typography>
                        <Typography variant="body2" component="div">
                          {businessDetails.business_catalogue == "" || businessDetails.business_catalogue == null ? '-' : businessDetails.business_catalogue}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Social media links */}
                  <Grid item xs={12}>
                    <Box mt={2} display="flex">
                      {businessDetails.business_facebook && (
                        <IconButton href={businessDetails.business_facebook} target="_blank">
                          <FacebookIcon style={{ color: "#3b5998" }} />
                        </IconButton>
                      )}
                      {businessDetails.business_youtube && (
                        <IconButton href={businessDetails.business_youtube} target="_blank">
                          <YouTubeIcon style={{ color: "#c4302b" }} />
                        </IconButton>
                      )}
                      {businessDetails.business_insta && (
                        <IconButton href={businessDetails.business_insta} target="_blank">
                          <InstagramIcon style={{ color: "#e1306c" }} />
                        </IconButton>
                      )}
                      {businessDetails.business_telegram && (
                        <IconButton href={businessDetails.business_insta} target="_blank">
                          <TelegramIcon style={{ color: "#0088cc" }} />
                        </IconButton>
                      )}
                      {businessDetails.business_linkedin && (
                        <IconButton href={businessDetails.business_linkedin} target="_blank">
                          <LinkedInIcon style={{ color: "#0077b5" }} />
                        </IconButton>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Box textAlign="center">
              <SentimentVeryDissatisfiedIcon style={{ fontSize: 80 }} />
              <Typography variant="h5">{Strings.Nobusinessdetailsfound}</Typography>
            </Box>
          )}
        </Box>
      </Card>
    </Box>
  );
}

export default BusinessDetails;
