import React, { useEffect, useState } from "react";
import { Strings } from "../Common/String";
import {
  Typography,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  FormControlLabel,
RadioGroup,
  Radio,
  Box,
  Checkbox,
  Button,
  IconButton,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  AddMemberAPI,
  UpdateMemberAPI,
  view_education_sub_type_API,
} from "../Features/services";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

export default function Memberregistration() {
  const location = useLocation();
  const navigate = useNavigate();

  const [isEdit] = useState(
    location && location.state && location.state?.rowData ? true : false
  );

  const optionsfoot = [
    { label: "1 Foot", value: "1 foot" },
    { label: "2 Foot", value: "2 foot" },
    { label: "3 Foot", value: "3 foot" },
    { label: "4 Foot", value: "4 foot" },
    { label: "5 Foot", value: "5 foot" },
    { label: "6 Foot", value: "6 foot" },
    { label: "7 Foot", value: "7 foot" },
    { label: "8 Foot", value: "8 foot" },
    { label: "10 Foot", value: "9 foot" },
    { label: "10 Foot", value: "10 foot" },
  ];
  const options1 = [
    { label: "0 Inch", value: "0 inch" },
    { label: "1 Inch", value: "1 inch" },
    { label: "2 Inch", value: "2 inch" },
    { label: "3 Inch", value: "3 inch" },
    { label: "4 Inch", value: "4 inch" },
    { label: "5 Inch", value: "5 inch" },
    { label: "6 Inch", value: "6 inch" },
    { label: "7 Inch", value: "7 inch" },
    { label: "8 Inch", value: "8 inch" },
    { label: "9 Inch", value: "9 inch" },
    { label: "10 Inch", value: "10 inch" },
    { label: "11 Inch", value: "11 inch" },
  ];

  const countryData = useSelector((state) => state.country.countryData);
  const reduxData = useSelector((state) => state.country);
  const relationData = useSelector((state) => state.country.relationData);
  const bloodgroupData = useSelector((state) => state.country.bloodgroupData);
  const educationData = useSelector((state) => state.country.educationData);
  const materialData = useSelector((state) => state.country.materialData);
  const occupationData = useSelector((state) => state.country.occupationData);
  const pragatiData = useSelector((state) => state.country.pragatiData);
  const visaData = useSelector((state) => state.country.visaData);
  const viewVillage = useSelector((state) => state.country.viewVillage);
  const [role, setRole] = useState("male");
  const [selectedRadio, setSelectedRadio] = useState("");

  const handleRoleChange = (event) => setRole(event.target.value);
  const [sakhdata, setsakhdata] = useState([]);
  const [sakh_id, setsakh_id] = useState();
  const [countrydata, setcountrydata] = useState([]);

  const [villagedata, setvillagedata] = useState([]);
  const [village_id, setvillage_id] = useState(
    isEdit ? location?.state?.rowData?.village_id : 0
  );
  const [maritaldata, setmaritaldata] = useState([]);
  const [marital_status, setmarital_status] = useState(
    isEdit ? location.state.rowData?.marital_status : 0
  );
  const [mother_name, setmother_name] = useState(
    isEdit ? location.state.rowData?.mother_name : ""
  );
  const [educationTypedata, seteducationTypedata] = useState([]);
  const [education_id, seteducation_id] = useState(
    isEdit ? location.state.rowData?.education_id : 0
  );
  const [educationsubTypedata, seteducationsubTypedata] = useState([]);
  const [education_sub_id, seteducation_sub_id] = useState(
    isEdit ? location.state.rowData?.education_sub_id : 0
  );
  const [occupationdata, setoccupationdata] = useState([]);
  const [occupation, setoccupation] = useState(
    isEdit ? location.state.rowData?.occupation : ""
  );
  const [mosaldata, setmosaldata] = useState([]);
  const [mosal, setmosal] = useState(
    isEdit ? location.state.rowData?.mosal : 0
  );
  const [blooddata, setblooddata] = useState([]);
  const [blood_group, setblood_group] = useState(
    isEdit ? location.state.rowData?.blood_group : 0
  );
  const [pragatidata, setpragatidata] = useState([]);
  const [pragatimandal_id, setpragatimandal_id] = useState(
    isEdit ? location.state.rowData?.pragatimandal_id : 0
  );
  const [height, setheight] = useState(
    isEdit ? location?.state?.rowData?.height.split(" ")[0] + " foot" : 0
  );
  const [weight, setweight] = useState(
    isEdit ? location.state.rowData?.weight : 0
  );
  const [heightinch, setheightinch] = useState(
    isEdit ? location?.state?.rowData?.height.split(" ")[2] + " inch" : 0
  );
  const [surname, setsurname] = useState(
    isEdit ? location.state.rowData?.surname : ""
  );
  const [first_name, setfirst_name] = useState(
    isEdit ? location.state.rowData?.first_name : ""
  );
  const [last_name, setlast_name] = useState(
    isEdit ? location.state.rowData?.last_name : ""
  );
  const [middle_name, setmiddle_name] = useState(
    isEdit ? location.state.rowData?.middle_name : ""
  );
  const [contact, setcontact] = useState(
    isEdit ? location.state.rowData?.contact : ""
  );
  const [contact_2, setcontact_2] = useState(
    isEdit ? location.state.rowData?.contact_2 : ""
  );
  const [email, setemail] = useState(
    isEdit ? location.state.rowData?.email : ""
  );
  const [designation, setdesignation] = useState(
    isEdit ? location.state.rowData?.designation : ""
  );
  const [company_name, setcompany_name] = useState(
    isEdit ? location.state.rowData?.company_name : ""
  );
  const [m_uncle_name, setm_uncle_name] = useState(
    isEdit ? location.state.rowData?.m_uncle_name : ""
  );
  const [selectedDate, setSelectedDate] = useState("");
  const [age, setage] = useState(isEdit ? location.state.rowData?.age : "");
  const [residence_address, setresidence_address] = useState(
    isEdit ? location.state.rowData?.residence_address : ""
  );
  const [occupation_address, setoccupation_address] = useState(
    isEdit ? location.state.rowData?.occupation_address : ""
  );
  const [about_me, setabout_me] = useState(
    isEdit ? location.state.rowData?.about_me : ""
  );
  const [relation_id, setrelation_id] = useState(
    isEdit ? location.state.rowData?.relation_type : 0
  );
  const [hide, setHide] = useState(false);

  const [errors, setErrors] = useState({});
  const [matrimonial_status, setmatrimonial_status] = useState(
    isEdit && location.state.rowData?.matrimonial_status
  );
  const [isChecked, setIsChecked] = useState(
    isEdit && location.state.rowData?.matrimonial_status ? true : false
  );
  const [
    matrimonial_interest_status_checked,
    setmatrimonial_interest_status_checked,
  ] = useState(
    isEdit && location.state.rowData?.matrimonial_status == "2" ? true : false
  );
  const [female_contact_display, setfemale_contact_display] = useState(0);
  const [country_code, setcountry_code] = useState("");
  const [member_image, setmember_image] = useState(
    isEdit ? location.state.rowData?.photo : 0
  );
  const [relationdata, setrelationdata] = useState([]);
  const [annual_income, setannual_income] = useState(
    isEdit ? location.state.rowData?.annual_income : ""
  );
  const [fileName, setFileName] = useState(
    isEdit ? location.state.rowData?.photo : ""
  );

  const [sub_site_selected, setSub_site_selected] = useState(
    isEdit
      ? [
          {
            label: location.state.rowData?.education_sub_name,
            value: location.state.rowData?.education_sub_id,
          },
        ]
      : []
  );
  const [visadata, setvisadata] = useState([]);
  const [country_id, setcountry_id] = useState(
    isEdit ? location.state.rowData?.country_id : 0
  );
  const [visa_type_name, setvisa_type_name] = useState(
    isEdit ? location.state.rowData?.visa_type_name : 0
  );
  const [showvisa, setshowvisa] = useState(
    isEdit && location.state.rowData?.country_id != 1 ? true : false
  );
  const [gender, setgender] = useState(
    isEdit ? location.state.rowData?.gender : "male"
  );
  const [blood_donate_status, setblood_donate_status] = useState(
    isEdit ? location.state.rowData?.blood_donate_status : 0
  );
  const [blood_donate_status_checked, setblood_donate_status_checked] =
    useState(
      isEdit && location.state.rowData?.blood_donate_status == "2"
        ? true
        : false
    );
  const [member_image_old, setmember_image_old] = useState(
    isEdit ? location.state.rowData?.photo : 0
  );

  const [isSubmitting, setIsSubmitting] = useState(false);

  const getDefaultDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    if (month < 10) {
      month = `0${month}`;
    }
    if (day < 10) {
      day = `0${day}`;
    }

    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    if (location.state?.rowData?.birthdate) {
      setSelectedDate(location.state.rowData.birthdate);
    } else {
      setSelectedDate(getDefaultDate());
    }
  }, [location.state]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};
    const datass =
      gender == "female (??????)"
        ? "female"
        : gender == "female (સ્ત્રી)"
        ? "female"
        : gender == "female"
        ? "female"
        : gender == "Female"
        ? "female"
        : gender == "male (?????)"
        ? "male"
        : gender == "male (પુરૂષ)"
        ? "male"
        : gender == "male"
        ? "male"
        : gender == "Male"
        ? "male"
        : "";
    if (surname == "") {
      newErrors.surname = Strings.surname_validation;
    }
    if (first_name == "") {
      newErrors.first_name = Strings.name_validation;
    }
    if (last_name == "") {
      newErrors.last_name = Strings.Pleasefathername;
    }
    if (residence_address == "") {
      newErrors.residence_address = Strings.Pleaseaddress;
    }
    // if (occupation == "") {
    //   newErrors.occupation = Strings.Pleaseoccupation;
    // }
    // if (designation == "") {
    //   newErrors.designation = Strings.Pleasedesignation;
    // }
    // if (about_me == "") {
    //   newErrors.about_me = Strings.Pleaseaboutme;
    // }
    if (country_id == "") {
      newErrors.country_id = Strings.Pleasecountry;
    }
    // if (m_uncle_name == "") {
    //   newErrors.m_uncle_name = Strings.Pleasename;
    // }
    // if (company_name == "") {
    //   newErrors.company_name = Strings.Pleasecompanyname;
    // }
    // if (occupation_address == "") {
    //   newErrors.occupation_address = Strings.Pleaseoccaddress;
    // }
    if (village_id == "") {
      newErrors.village_id = Strings.Pleasenative;
    }
    if (email == "") {
      newErrors.email = Strings.Pleaseemail;
    }
    if (selectedDate == "") {
      newErrors.selectedDate = Strings.birth_validation;
    }
    if (relation_id == 0) {
      newErrors.relation_id = Strings.validation_relation;
    }
    // if (village_id == 0) {
    //   newErrors.village_id = Strings.PleaseselectNative;
    // }
    if (marital_status == 0) {
      newErrors.marital_status = Strings.validation_marital_status;
    }
    // if (mother_name == 0) {
    //   newErrors.mother_name = Strings.Pleasemothername;
    // }
    if (blood_group == 0) {
      newErrors.blood_group = Strings.Pleasebloodgroup;
    }
    // if (country_code !== "India" && visa_type_name !== 0) {
    //   newErrors.visa_type_name = "Please enter visa type";
    // }
    if (contact_2 == "") {
      newErrors.contact_2 = Strings.city_location_validation;
    }
    if (education_id == 0) {
      newErrors.education_id = Strings.validation_eduction;
    }
    if (education_sub_id == 0) {
      newErrors.education_sub_id = Strings.validation_sub_eduction;
    }
    if (occupation == 0) {
      newErrors.occupation = Strings.validation_occupation;
    }
    // if (mosal == 0) {
    //   newErrors.mosal = Strings.validation_mosal;
    // }
    // if (country_id == 0) {
    //   newErrors.country_id = Strings.validation_country;
    // }
    // if (blood_group == 0) {
    //   newErrors.blood_group = Strings.validation_blood_group;
    // }
    if (height == 0) {
      newErrors.height = Strings.validation_height;
    }
    if (heightinch == 0) {
      newErrors.heightinch = Strings.validation_height;
    }
    if (weight == 0) {
      newErrors.weight = Strings.validation_weight;
    }
    if (fileName == 0) {
      newErrors.fileName = Strings.validation_select_image;
    }
    setErrors(newErrors);
    const errorKeys = Object.keys(newErrors);
    if (errorKeys.length > 0) {
      return;
    } else {
      let response;
      try {
        if (location && location.state && isEdit) {
          response = await UpdateMemberAPI({
            family_member_id: location.state?.rowData.family_member_id,
            relation_type: relation_id,
            surname: surname,
            first_name: first_name,
            middle_name: middle_name,
            last_name: last_name,
            gender: datass,
            birthdate: selectedDate,
            age: age,
            height: height + " " + heightinch,
            contact_2: contact_2,
            email: email,
            weight: weight,
            contact: contact,
            visa_type_name: visa_type_name,
            education_id: education_id,
            education_sub_id: education_sub_id,
            village_id: village_id,
            occupation: occupation,
            company_name: company_name,
            occupation_address: occupation_address,
            mosal: mosal,
            mother_name: mother_name,
            marital_status: marital_status,
            m_uncle_name: m_uncle_name,
            blood_group: blood_group,
            country_id: country_id,
            // pragatimandal_id: pragatimandal_id,
            matrimonial_status:
              marital_status == "2"
                ? "1"
                : marital_status == "4"
                ? "1"
                : marital_status == "8"
                ? "1"
                : matrimonial_status,
            blood_donate_status: blood_donate_status,
            designation: designation,
            annual_income: annual_income,
            female_contact_display: female_contact_display,
            about_me: about_me,
            country_code: country_id,
            member_image_old: member_image_old,
            member_image: fileName,
            residence_address: residence_address,
          });
          if (response.ok === true) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: response.message,
              showConfirmButton: true,
              timer: 2000,
            });
            navigate("/familymembers");
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: response.message,
              showConfirmButton: true,
            });
          }
        } else {
          const doc =  {
            name: fileName?.name, 
            type: fileName?.type, 
            uri: ""
          }
          response = await AddMemberAPI({
            relation_type: relation_id,
            surname: surname,
            first_name: first_name,
            middle_name: middle_name,
            last_name: last_name,
            gender: datass,
            birthdate: selectedDate,
            age: age,
            height: height + " " + heightinch,
            contact_2: contact_2,
            email: email,
            weight: weight,
            contact: contact,
            visa_type_name: visa_type_name,
            education_id: education_id,
            education_sub_id: education_sub_id,
            village_id: village_id,
            occupation: occupation,
            company_name: company_name,
            occupation_address: occupation_address,
            mosal: mosal,
            mother_name: mother_name,
            marital_status: marital_status,
            m_uncle_name: m_uncle_name,
            blood_group: blood_group,
            country_id: country_id,
            // pragatimandal_id: pragatimandal_id,
            matrimonial_status:
              marital_status == "2"
                ? "1"
                : marital_status == "4"
                ? "1"
                : marital_status == "8"
                ? "1"
                : matrimonial_status,
            blood_donate_status: blood_donate_status,
            designation: designation,
            annual_income: annual_income,
            female_contact_display: female_contact_display,
            about_me: about_me,
            country_code: country_id,
            member_image: fileName,
            residence_address: residence_address,
          });
          if (response.ok === true) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: response.message,
              showConfirmButton: true,
              timer: 2000,
            });
            navigate("/familymembers");
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: response.message,
              showConfirmButton: true,
            });
          }
        }
      } catch (error) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Something went wrong",
          showConfirmButton: true,
        });
      } finally {
        // setIsSubmitting(false);
      }
    }
  };

  const calculateAge = (date) => {
    if (date) {
      const currentDate = new Date();
      const birthDate = new Date(date);
      const ageInMillis = currentDate - birthDate;
      const calculatedAge = Math.floor(
        ageInMillis / (1000 * 60 * 60 * 24 * 365)
      );
      setage(calculatedAge);
    } else {
      setage("");
    }
  };

  const generateOptions = (start, end) => {
    const options = [];
    for (let i = start; i <= end; i++) {
      options.push({
        value: i.toString() + " Kg",
        label: i.toString() + " Kg",
      });
    }
    return options;
  };

  const options = generateOptions(1, 150);

  // useEffect(() => {
  //   (async () => {
  //     const bloodResponce = await View_Sakh_API();
  //     if (bloodResponce) {
  //       setsakhdata(
  //         bloodResponce.data
  //           .sort((a, b) => a.sakh_name.localeCompare(b.sakh_name))
  //           .map((item) => {
  //             return {
  //               label: item.sakh_name,
  //               value: item.sakh_id,
  //             };
  //           })
  //       );
  //     }
  //   })();
  // }, []);

  useEffect(() => {
    if (reduxData && reduxData.villagedata) {
      const sortedCountries = reduxData.villagedata
        .slice()
        .sort((a, b) => a.sakh_name.localeCompare(b.sakh_name));
      const mappedCountries = sortedCountries.map((item) => ({
        label: item.sakh_name,
        value: item.sakh_id,
      }));
      setsakhdata(mappedCountries);
    }
  }, [villagedata]);

  function handleChangeSakh(event) {
    setsakh_id(event.target.value);
  }

  // useEffect(() => {
  //   (async () => {
  //     const bloodResponce = await CurrentCountry_API();
  //     if (bloodResponce) {
  //       setcountrydata(
  //         bloodResponce.data
  //           .sort((a, b) => a.country_name.localeCompare(b.country_name))
  //           .map((item) => {
  //             return {
  //               label: item.country_name,
  //               value: item.country_id,
  //             };
  //           })
  //       );
  //     }
  //   })();
  // }, []);

  useEffect(() => {
    // (async () => {
    //   const bloodResponce = countryData;
    //   if (bloodResponce) {
    //     console.log('bloodResponce ', bloodResponce, '>>>>>>', countryData)
    //     setcountrydata(
    //       bloodResponce
    //         .sort((a, b) => a.country_name.localeCompare(b.country_name))
    //         .map((item) => {
    //           return {
    //             label: item.country_name,
    //             value: item.country_id,
    //           };
    //         })
    //     );
    //   }
    // })();

    if (reduxData && reduxData.countryData) {
      const sortedCountries = reduxData.countryData
        .slice()
        .sort((a, b) => a.country_name.localeCompare(b.country_name));
      const mappedCountries = sortedCountries.map((item) => ({
        label: item.country_name,
        value: item.country_id,
      }));
      setcountrydata(mappedCountries);
    }
  }, [countryData]);

  const handelshowhide = (event) => {
    setcountry_code(event.target.value);
    setcountry_id(event.target.value);
    if (event.target.value == 1) {
      setshowvisa(false);
    } else {
      setshowvisa(true);
    }
  };
  function handleChangevisatype(event) {
    setvisa_type_name(event.target.value);
  }

  // useEffect(() => {
  //   (async () => {
  //     const villageResponce = await view_village_allAPI({
  //       offset: offset,
  //     });
  //     if (villageResponce) {
  //       setvillagedata(
  //         villageResponce
  //           .sort((a, b) => a.village_name.localeCompare(b.village_name))
  //           .map((item) => {
  //             return {
  //               label: item.village_name,
  //               value: item.village_id,
  //             };
  //           })
  //       );
  //     }
  //   })();
  // }, []);

  useEffect(() => {
    if (reduxData && reduxData.viewVillage) {
      const sortedCountries = reduxData.viewVillage
        .slice()
        .sort((a, b) => a.village_name.localeCompare(b.village_name));
      const mappedCountries = sortedCountries.map((item) => ({
        label: item.village_name,
        value: item.village_id,
      }));
      setvillagedata(mappedCountries);
    }
  }, [viewVillage]);

  function handleChangevillage(event) {
    setvillage_id(event.target.value);
  }
  function handleChange(event) {
    setmosal(event.target.value);
  }

  // useEffect(() => {
  //   (async () => {
  //     const bloodResponce = await Marital_API();
  //     if (bloodResponce) {
  //       setmaritaldata(
  //         bloodResponce.data
  //           .sort((a, b) => a.marital_name.localeCompare(b.marital_name))
  //           .map((item) => {
  //             return {
  //               label: item.marital_name,
  //               value: item.marital_id,
  //             };
  //           })
  //       );
  //     }
  //   })();
  // }, []);

  useEffect(() => {
    if (reduxData && reduxData.materialData) {
      const sortedCountries = reduxData.materialData
        .slice()
        .sort((a, b) => a.marital_name.localeCompare(b.marital_name));
      const mappedCountries = sortedCountries.map((item) => ({
        label: item.marital_name,
        value: item.marital_id,
      }));
      setmaritaldata(mappedCountries);
    }
  }, [materialData]);

  const handleShowbox = (event) => {
    const value = event.target.value;
    setmarital_status(value);
    setHide(value !== "2" && value !== "4" && value !== "8");
  };

  // useEffect(() => {
  //   (async () => {
  //     const EducationTypeResponce = await EducationType_API();
  //     if (EducationTypeResponce) {
  //       seteducationTypedata(
  //         EducationTypeResponce.data
  //           .sort((a, b) => a.education_name.localeCompare(b.education_name))
  //           .map((item) => {
  //             return {
  //               label: item.education_name,
  //               value: item.education_id,
  //             };
  //           })
  //       );
  //     }
  //   })();
  // }, []);

  useEffect(() => {
    if (reduxData && reduxData.educationData) {
      const sortedCountries = reduxData.educationData
        .slice()
        .sort((a, b) => a.education_name.localeCompare(b.education_name));
      const mappedCountries = sortedCountries.map((item) => ({
        label: item.education_name,
        value: item.education_id,
      }));
      seteducationTypedata(mappedCountries);
    }
  }, [educationData]);

  function getsubsite(e) {
    seteducation_id(e.target.value);
    seteducation_sub_id(0);
  }
  useEffect(() => {
    (async () => {
      const CategoryResponce = await view_education_sub_type_API({
        education_id: education_id,
      });
      if (CategoryResponce && CategoryResponce?.data?.length > 0) {
        seteducationsubTypedata(
          CategoryResponce.data
            .sort((a, b) =>
              a.education_sub_name.localeCompare(b.education_sub_name)
            )
            .map((item) => {
              return {
                label: item.education_sub_name,
                value: item.education_sub_id,
              };
            })
        );
      }
    })();
  }, [education_id]);

  function setsubsite(e) {
    setSub_site_selected(e.target.value);
    seteducation_sub_id(e.target.value);
  }

  // useEffect(() => {
  //   (async () => {
  //     const occupationResponce = await Occupation_API();
  //     if (occupationResponce) {
  //       setoccupationdata(
  //         occupationResponce.data
  //           .sort((a, b) => a.occupation_name.localeCompare(b.occupation_name))
  //           .map((item) => {
  //             return {
  //               label: item.occupation_name,
  //               value: item.occupation_id,
  //             };
  //           })
  //       );
  //     }
  //   })();
  // }, []);

  useEffect(() => {
    if (reduxData && reduxData.occupationData) {
      const sortedCountries = reduxData.occupationData
        .slice()
        .sort((a, b) => a.occupation_name.localeCompare(b.occupation_name));
      const mappedCountries = sortedCountries.map((item) => ({
        label: item.occupation_name,
        value: item.occupation_id,
      }));
      setoccupationdata(mappedCountries);
    }
  }, [occupationData]);

  function handleChangeoccupation(event) {
    setoccupation(event.target.value);
  }

  // useEffect(() => {
  //   (async () => {
  //     const villageResponce = await view_village_allAPI({
  //       offset: offset,
  //     });
  //     if (villageResponce) {
  //       setmosaldata(
  //         villageResponce
  //           .sort((a, b) => a.village_name.localeCompare(b.village_name))
  //           .map((item) => {
  //             return {
  //               label: item.village_name,
  //               value: item.village_name,
  //             };
  //           })
  //       );
  //     }
  //   })();
  // }, []);

  useEffect(() => {
    if (reduxData && reduxData.viewVillage) {
      const sortedCountries = reduxData.viewVillage
        .slice()
        .sort((a, b) => a.village_name.localeCompare(b.village_name));
      const mappedCountries = sortedCountries.map((item) => ({
        label: item.village_name,
        value: item.village_name,
      }));
      setmosaldata(mappedCountries);
    }
  }, [viewVillage]);

  // useEffect(() => {
  //   (async () => {
  //     const bloodResponce = await Bloodgroup_API();
  //     if (bloodResponce) {
  //       setblooddata(
  //         // bloodResponce.data.sort((a, b) => a.group_name.localeCompare(b.group_name))
  //         bloodResponce.data.map((item) => {
  //           return {
  //             label: item.group_name,
  //             value: item.group_name,
  //           };
  //         })
  //       );
  //     }
  //   })();
  // }, []);

  useEffect(() => {
    if (reduxData && reduxData.bloodgroupData) {
      // const sortedCountries = pragatiData.slice().sort((a, b) => a.group_name.localeCompare(b.group_name));
      const mappedCountries = reduxData.bloodgroupData.map((item) => ({
        label: item.group_name,
        value: item.group_name,
      }));
      setblooddata(mappedCountries);
    }
  }, [bloodgroupData]);

  function handleblood(event) {
    setblood_group(event.target.value);
  }

  // useEffect(() => {
  //   (async () => {
  //     const bloodResponce = await Pragati_API();
  //     if (bloodResponce) {
  //       setpragatidata(
  //         bloodResponce.data
  //           .sort((a, b) =>
  //             a.pragatimandal_name.localeCompare(b.pragatimandal_name)
  //           )
  //           .map((item) => {
  //             return {
  //               label: item.pragatimandal_name,
  //               value: item.pragatimandal_id,
  //             };
  //           })
  //       );
  //     }
  //   })();
  // }, []);

  useEffect(() => {
    if (reduxData && reduxData.pragatiData) {
      const sortedCountries = reduxData.pragatiData
        .slice()
        .sort((a, b) =>
          a.pragatimandal_name.localeCompare(b.pragatimandal_name)
        );
      const mappedCountries = sortedCountries.map((item) => ({
        label: item.pragatimandal_name,
        value: item.pragatimandal_id,
      }));
      setpragatidata(mappedCountries);
    }
  }, [pragatiData]);

  // useEffect(() => {
  //   (async () => {
  //     const relationResponce = await Relation_API();
  //     if (relationResponce) {
  //       setrelationdata(
  //         relationResponce.data
  //           .sort((a, b) => a.relation_name.localeCompare(b.relation_name))
  //           .map((item) => {
  //             return {
  //               label: item.relation_name,
  //               value: item.relation_id,
  //             };
  //           })
  //       );
  //     }
  //   })();
  // }, []);

  useEffect(() => {
    if (reduxData && reduxData.relationData) {
      const sortedCountries = reduxData.relationData
        .slice()
        .sort((a, b) => a.relation_name.localeCompare(b.relation_name));
      const mappedCountries = sortedCountries.map((item) => ({
        label: item.relation_name,
        value: item.relation_id,
      }));
      setrelationdata(mappedCountries);
    }
  }, [relationData]);

  // useEffect(() => {
  //   (async () => {
  //     const bloodResponce = await ViewVisa_API();
  //     if (bloodResponce) {
  //       setvisadata(
  //         bloodResponce.data
  //           .sort((a, b) => a.visa_type_name.localeCompare(b.visa_type_name))
  //           .map((item) => {
  //             return {
  //               label: item.visa_type_name,
  //               value: item.visa_type_name,
  //             };
  //           })
  //       );
  //     }
  //   })();
  // }, []);

  useEffect(() => {
    if (reduxData && reduxData.visaData) {
      const sortedCountries = reduxData.visaData
        .slice()
        .sort((a, b) => a.visa_type_name.localeCompare(b.visa_type_name));
      const mappedCountries = sortedCountries.map((item) => ({
        label: item.visa_type_name,
        value: item.visa_type_name,
      }));
      setvisadata(mappedCountries);
    }
  }, [visaData]);

  function handleChangerelation(event) {
    setrelation_id(event.target.value);
  }

  function handlehieght(event) {
    setheight(event.target.value);
  }

  function handleChangeinch(event) {
    setheightinch(event.target.value);
  }

  function handleChangeweight(event) {
    setweight(event.target.value);
  }

  const handleRadioChange = (event) => {
    const selectedGender = event.target.value;
    setSelectedRadio(selectedGender == "male");
    setgender(selectedGender);
    setRole(event.target.value);
  };

  const handleChangeChk = (chkValue) => {
    if (chkValue.target.checked === true) {
      setblood_donate_status("2");
    } else {
      setblood_donate_status("1");
    }
    setblood_donate_status_checked(chkValue.target.checked);
  };

  const isUserOver18 = (selectedDate) => {
    const today = new Date();
    const birthDate = new Date(selectedDate);
    const age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
  
    // Adjust age if the birth date has not yet occurred this year
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      return age - 1;
    }
  
    return age;
  };

  const handleChangemarraige = (Value) => {
    // Assuming `selectedDate` is available in your component's state
    const age = isUserOver18(selectedDate);
  
    if (age >= 18) {
      if (Value.target.checked) {
        setmatrimonial_status("2");
      } else {
        setmatrimonial_status("1");
      }
      setIsChecked(Value.target.checked);
      setmatrimonial_interest_status_checked(Value.target.checked);
    } else {
      // Notify the user if they are under 18
      alert("You must be at least 18 years old to select this option.");
      // Optionally, uncheck the checkbox if it was just checked
      setIsChecked(false);
      setmatrimonial_interest_status_checked(false);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    console.log('e datz ', e);
    setFileName(file ? file.name : "");
  };

  const calculateAge1 = (dateOfBirth) => {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
  
    // Adjust age if the birth date has not yet occurred this year
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
  
    return age;
  };

  const handleDateChange = (e) => {
    const newDate = e.target.value;
    setSelectedDate(newDate);
    validateDate(newDate);

    if (newDate) {
      const calculatedAge = calculateAge1(newDate);
      setage(calculatedAge);
    } else {
      setage(""); // Clear age if no date is selected
    }
  };

  const validateDate = (date) => {
    if (!date) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        selectedDate: "Date of birth is required",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        selectedDate: "",
      }));
    }
  };
  

  useEffect(() => {
    const matrimonialStatus = location.state?.rowData?.marital_status;
    console.log('matrimonialStatus ', location.state)
    // Determine if checkbox should be shown based on matrimonialStatus
    // const shouldShowCheckbox = matrimonialStatus !== "2" && matrimonialStatus !== "4" && matrimonialStatus !== "8";
    if(matrimonialStatus != "2" && matrimonialStatus != "4" && matrimonialStatus != "8") {
      setHide(true);
    } else {
      setHide(false);
    }
  },[])

  return (
    <div>
      <Box my={3}>
        {/* <div>
          <Typography
            sx={{ fontSize: "20px", fontWeight: 500, color: "#949494" }}
          >
            {Strings.MemberRegisterForm}
          </Typography>
        </div> */}
          <Box display="flex" alignItems="center" mt={2} mb={2}>
          {isEdit == true ? 
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        : "" }
        <Typography style={{ fontWeight: 600, marginLeft: '10px' }}>
          {Strings.MemberRegisterForm}
        </Typography>
      </Box>
        <Grid container spacing={3}>
          {/* First Column */}
          <Grid item xs={12} md={6}>
            <div style={{ padding: 16 }}>
              <div style={{ marginBottom: 8 }}>
                <Typography
                  variant="caption"
                  color="text"
                  fontWeight="bold"
                  textTransform="uppercase"
                ></Typography>
              </div>

              <div style={{ listStyle: "none", margin: 0, padding: 0 }}>
                <InputLabel
                  sx={{
                    fontSize: "small",
                    fontWeight: "bold",
                  }}
                >
                  {Strings.Image}
                </InputLabel>
                <div style={{ position: "relative" }}>
                  <input
                    type="file"
                    id="fileInput"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      opacity: 0,
                      cursor: "pointer",
                    }}
                    onChange={(e) => {
                      handleFileChange(e);
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        fileName: "",
                      }));
                    }}
                    InputProps={{
                      style: {
                        color: "#888888",
                      },
                    }}
                  />
                  <div
                    style={{
                      position: "relative",
                      zIndex: 1,
                      cursor: "pointer",
                      border: "1px solid #ccc",
                      padding: "18px 2px",
                      borderRadius: "8px",
                    }}
                    onClick={() => document.getElementById("fileInput").click()}
                  >
                    <span
                      style={{
                        color: "#888",
                        fontSize: "15px",
                        padding: "10px",
                      }}
                    >
                      {fileName || Strings.SelectImage}
                    </span>
                  </div>
                  {errors.fileName && (
                    <Typography variant="caption" color="error">
                      {errors.fileName}
                    </Typography>
                  )}
                </div>

                {/* Field 2: Relation Type */}
                <InputLabel
                  sx={{
                    fontSize: "small",
                    fontWeight: "bold",
                    // marginBottom: "6px",
                    marginTop: "14px",
                  }}
                >
                  {Strings.RelationType}
                </InputLabel>
                <Select
                  fullWidth
                  value={relation_id}
                  onChange={(e) => {
                    handleChangerelation(e);
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      relation_id: "",
                    }));
                  }}
                  style={{
                    color: "#AEAFAF",
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "#AEAFAF",
                    },
                    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                      borderBottomColor: "#AEAFAF",
                    },
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                  displayEmpty
                  defaultValue={{
                    value: isEdit ? location.state.rowData.relation_id : 0,
                    label: isEdit
                      ? location.state.rowData.relation_name
                      : Strings.SelectRelationType,
                  }}
                >
                  <MenuItem value={0} disabled>
                    {Strings.SelectRelationType}
                  </MenuItem>
                  {relationdata.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                {errors.relation_id && (
                  <Typography variant="caption" color="error">
                    {errors.relation_id}
                  </Typography>
                )}

                {/* Field 3: Surname */}
                <InputLabel
                  sx={{
                    fontSize: "small",
                    fontWeight: "bold",
                    marginTop: "14px",
                  }}
                >
                  {Strings.Surname}
                </InputLabel>
                <TextField
                  fullWidth
                  placeholder={Strings.EnterSurname}
                  onChange={(e) => {
                    setsurname(e.target.value);
                    setErrors((prevErrors) => ({ ...prevErrors, surname: "" }));
                  }}
                  value={surname}
                  defaultValue={isEdit ? location.state.rowData.surname : ""}
                  InputProps={{
                    style: {
                      color: "#888888",
                      fontWeight: 500,
                    },
                  }}
                />
                {errors.surname && (
                  <Typography variant="caption" color="error">
                    {errors.surname}
                  </Typography>
                )}

                {/* Field 4: First Name */}
                <InputLabel
                  sx={{
                    fontSize: "small",
                    fontWeight: "bold",
                    marginTop: "14px",
                  }}
                >
                  {Strings.FirstName}
                </InputLabel>
                <TextField
                  fullWidth
                  placeholder={Strings.EnterFirstName}
                  onChange={(e) => {
                    setfirst_name(e.target.value);
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      first_name: "",
                    }));
                  }}
                  InputProps={{
                    style: {
                      color: "#888888",
                      fontWeight: 500,
                    },
                  }}
                  value={first_name}
                  defaultValue={isEdit ? location.state.rowData.first_name : ""}
                />
                {errors.first_name && (
                  <Typography variant="caption" color="error">
                    {errors.first_name}
                  </Typography>
                )}

                {/* Field 5: Last Name */}
                <InputLabel
                  sx={{
                    fontSize: "small",
                    fontWeight: "bold",
                    // marginBottom: "6px",
                    marginTop: "14px",
                  }}
                >
                  {Strings.FatherorHusbandName}
                </InputLabel>
                <TextField
                  fullWidth
                  placeholder={Strings.FatherorHusbandName}
                  onChange={(e) => {
                    setlast_name(e.target.value);
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      last_name: "",
                    }));
                  }}
                  InputProps={{
                    style: {
                      color: "#888888",
                      fontWeight: 500,
                    },
                  }}
                  value={last_name}
                  defaultValue={isEdit ? location.state.rowData.last_name : ""}
                />
                {errors.last_name && (
                  <Typography variant="caption" color="error">
                    {errors.last_name}
                  </Typography>
                )}

                {/* Field 6: Grand Father Name */}
                {relation_id === 1 && (
                  <>
                    <InputLabel
                      sx={{
                        fontSize: "small",
                        fontWeight: "bold",
                        marginTop: "14px",
                      }}
                    >
                      {Strings.GrandFatherName}
                    </InputLabel>
                    <TextField
                      fullWidth
                      placeholder={Strings.EnterGrandFatherName}
                      onChange={(e) => {
                        setmiddle_name(e.target.value);
                      }}
                      InputProps={{
                        style: {
                          color: "#888",
                        },
                      }}
                      value={middle_name}
                      defaultValue={
                        isEdit ? location.state.rowData.middle_name : ""
                      }
                    />
                  </>
                )}

                {/* Field 7: Gender */}
                <InputLabel
                  sx={{
                    fontSize: "small",
                    fontWeight: "bold",
                    marginTop: "14px",
                  }}
                >
                  {" "}
                  {Strings.gender}
                </InputLabel>
                <RadioGroup
                  style={{ marginLeft: "3%", marginBottom: "16px" }}
                  aria-label="role"
                  name={Strings.role}
                  value={role}
                  onChange={handleRoleChange}
                  row
                >
                  <FormControlLabel
                    value={Strings.male}
                    control={<Radio />}
                    label={Strings.male}
                    checked={
                      gender == "male" ||
                      gender == "Male" ||
                      gender == "male (પુરૂષ)" ||
                      gender == "male (?????)"
                    }
                    onChange={(e) => {
                      handleRadioChange(e);
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        gender: "",
                      }));
                    }}
                  />
                  <FormControlLabel
                    value={Strings.female}
                    control={<Radio />}
                    label={Strings.female}
                    checked={
                      gender == "female" ||
                      gender == "Female" ||
                      gender == "female (સ્ત્રી)" ||
                      gender == "female (??????)"
                    }
                    onChange={(e) => {
                      handleRadioChange(e);
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        gender: "",
                      }));
                    }}
                  />
                </RadioGroup>
                {errors.gender && (
                  <Typography variant="caption" color="error">
                    {errors.gender}
                  </Typography>
                )}

                {/* Field 8: Phone */}
                <InputLabel
                  sx={{
                    fontSize: "small",
                    fontWeight: "bold",
                    marginTop: "14px",
                  }}
                >
                  {Strings.Phone}
                </InputLabel>
                <TextField
                  fullWidth
                  type="number"
                  placeholder={Strings.EnterPhoneNumber}
                  onChange={(e) => {
                    setcontact(e.target.value);
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      contact_2: "",
                    }));
                  }}
                  InputProps={{
                    style: {
                      color: "#888888",
                      fontWeight: 500,
                    },
                  }}
                  value={contact}
                  defaultValue={isEdit ? location.state.rowData.contact : ""}
                />
                {errors.contact_2 && (
                  <Typography variant="caption" color="error">
                    {errors.contact_2}
                  </Typography>
                )}

                {/* Field 9: Email */}
                <InputLabel
                  sx={{
                    fontSize: "small",
                    fontWeight: "bold",
                    marginTop: "14px",
                  }}
                >
                  {Strings.Email}
                </InputLabel>
                <TextField
                  fullWidth
                  placeholder={Strings.EnterEmail}
                  onChange={(e) => {
                    setemail(e.target.value);
                    setErrors((prevErrors) => ({ ...prevErrors, email: "" }));
                  }}
                  InputProps={{
                    style: {
                      color: "#888888",
                      fontWeight: 500,
                    },
                  }}
                  value={email}
                  defaultValue={isEdit ? location.state.rowData.email : ""}
                />
                {errors.email && (
                  <Typography variant="caption" color="error">
                    {errors.email}
                  </Typography>
                )}

                {/* Field 10: Date of Birth */}
                <InputLabel
                  sx={{
                    fontSize: "small",
                    fontWeight: "bold",
                    marginTop: "14px",
                  }}
                >
                  {Strings.DateofBirth}
                </InputLabel>
                <TextField
                  fullWidth
                  type="date"
                  onChange={(e) => {
                    handleDateChange(e);
                  }}
                  value={selectedDate}
                  defaultValue={selectedDate}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    style: {
                      color: "#888888",
                      fontWeight: 500,
                    },
                  }}
                  inputProps={{
                    placeholder: "",
                  }}
                />
                {errors.selectedDate && (
                  <Typography variant="caption" color="error">
                    {errors.selectedDate}
                  </Typography>
                )}

                {/* Field 11: Age */}
                <InputLabel
                  sx={{
                    fontSize: "small",
                    fontWeight: "bold",
                    marginTop: "14px",
                  }}
                >
                  {Strings.Age}
                </InputLabel>
                <TextField
                  fullWidth
                  placeholder={Strings.Age}
                  value={age}
                  readOnly
                  disabled
                  // onChange={(e) => {
                  //   setage(e.target.value);
                  // }}
                  InputProps={{
                    style: {
                      color: "#888888",
                      fontWeight: 500,
                    },
                  }}
                  defaultValue={isEdit ? location.state.rowData.age : ""}
                />

                {/* Field 12: Village */}
                <InputLabel
                  sx={{
                    fontSize: "small",
                    fontWeight: "bold",
                    marginTop: "14px",
                  }}
                >
                  {Strings.Village}
                </InputLabel>
                <Select
                  fullWidth
                  value={village_id}
                  onChange={(e) => {
                    handleChangevillage(e);
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      village_id: "",
                    }));
                  }}
                  displayEmpty
                  defaultValue={{
                    value: isEdit ? location.state.rowData.village_id : 0,
                    label: isEdit
                      ? location.state.rowData.village_name ||
                        JSON.parse(localStorage?.getItem("token"))?.village_name
                      : Strings.SelectVillage,
                  }}
                  style={{
                    color: "#AEAFAF",
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "#AEAFAF",
                    },
                    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                      borderBottomColor: "#AEAFAF",
                    },
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value={0} disabled>
                    {Strings.SelectVillage}
                  </MenuItem>
                  {villagedata.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                {errors.village_id && (
                  <Typography variant="caption" color="error">
                    {errors.village_id}
                  </Typography>
                )}

                <InputLabel
                  sx={{
                    fontSize: "small",
                    fontWeight: "bold",
                    marginTop: "14px",
                  }}
                >
                  {Strings.residence_address}
                </InputLabel>
                <TextField
                  fullWidth
                  placeholder={Strings.residence_address}
                  onChange={(e) => {
                    setresidence_address(e.target.value);
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      residence_address: "",
                    }));
                  }}
                  value={residence_address}
                  defaultValue={
                    isEdit ? location.state.rowData.residence_address : ""
                  }
                  InputProps={{
                    style: {
                      color: "#888888",
                      fontWeight: 500,
                    },
                  }}
                />
                {errors.residence_address && (
                  <Typography variant="caption" color="error">
                    {errors.residence_address}
                  </Typography>
                )}

                {/* Field 2: City/Location */}
                <InputLabel
                  sx={{
                    fontSize: "small",
                    fontWeight: "bold",
                    marginTop: "14px",
                  }}
                >
                  {Strings.city_location}
                </InputLabel>
                <TextField
                  fullWidth
                  placeholder={Strings.city_location}
                  onChange={(e) => {
                    setcontact_2(e.target.value);
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      contact_2: "",
                    }));
                  }}
                  InputProps={{
                    style: {
                      color: "#888888",
                      fontWeight: 500,
                    },
                  }}
                  value={contact_2}
                  defaultValue={isEdit ? location.state.rowData.contact_2 : ""}
                />
                {errors.contact_2 && (
                  <Typography variant="caption" color="error">
                    {errors.contact_2}
                  </Typography>
                )}

                {/* Field 3: Marital Status */}
                <InputLabel
                  sx={{
                    fontSize: "small",
                    fontWeight: "bold",
                    marginTop: "14px",
                  }}
                >
                  {Strings.marital_status}
                </InputLabel>
                <Select
                  fullWidth
                  placeholder="Select Marital Status"
                  value={marital_status}
                  onChange={(e) => {
                    handleShowbox(e);
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      marital_status: "",
                    }));
                  }}
                  defaultValue={{
                    value: isEdit
                      ? location.state.rowData.matrimonial_status
                      : 0,
                    label: isEdit
                      ? location.state.rowData.marital_status
                      : Strings.select_marital_status,
                  }}
                  style={{
                    color: "#AEAFAF",
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "#AEAFAF",
                    },
                    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                      borderBottomColor: "#AEAFAF",
                    },
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value={0} disabled>
                    {Strings.select_marital_status}
                  </MenuItem>
                  {maritaldata.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                {errors.marital_status && (
                  <Typography variant="caption" color="error">
                    {errors.marital_status}
                  </Typography>
                )}

                {/* Field 4: Matrimonial Interest */}
                {hide && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={matrimonial_interest_status_checked}
                        onChange={handleChangemarraige}
                      />
                    }
                    sx={{ color: "red" }}
                    label={Strings.matrimonial_interest}
                  />
                )}

                {/* Field 5: Education Type */}
                <InputLabel
                  sx={{
                    fontSize: "small",
                    fontWeight: "bold",
                    marginTop: "14px",
                  }}
                >
                  {Strings.education_type}
                </InputLabel>
                <Select
                  fullWidth
                  placeholder="Select Education Type"
                  value={education_id}
                  onChange={(e) => {
                    getsubsite(e);
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      education_id: "",
                    }));
                  }}
                  style={{
                    color: "#AEAFAF",
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "#AEAFAF",
                    },
                    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                      borderBottomColor: "#AEAFAF",
                    },
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                  displayEmpty
                  defaultValue={{
                    value: isEdit ? location.state.rowData.education_id : 0,
                    label: isEdit
                      ? location.state.rowData.education_name
                      : Strings.select_education_type,
                  }}
                >
                  <MenuItem value={0} disabled>
                    {Strings.select_education_type}
                  </MenuItem>
                  {educationTypedata.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                {errors.education_id && (
                  <Typography variant="caption" color="error">
                    {errors.education_id}
                  </Typography>
                )}
              </div>
            </div>
          </Grid>

          {/* Second Column */}
          <Grid item xs={12} md={6}>
            <div style={{ padding: 16 }}>
              <div style={{ marginBottom: 8 }}>
                <Typography
                  variant="caption"
                  color="text"
                  fontWeight="bold"
                  textTransform="uppercase"
                ></Typography>
              </div>

              <div style={{ listStyle: "none", margin: 0, padding: 0 }}>
                {/* Field 7: Occupation */}
                <InputLabel
                  sx={{
                    fontSize: "small",
                    fontWeight: "bold",
                    marginTop: "14px",
                  }}
                >
                  {Strings.education_sub_type}
                </InputLabel>
                <Select
                  fullWidth
                  placeholder="Select Education Sub Type"
                  value={education_sub_id}
                  onChange={(e) => {
                    setsubsite(e);
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      education_sub_id: "",
                    }));
                  }}
                  style={{
                    color: "#AEAFAF",
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "#AEAFAF",
                    },
                    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                      borderBottomColor: "#AEAFAF",
                    },
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                  defaultValue={educationsubTypedata[0]}
                >
                  <MenuItem value={0} disabled>
                    {Strings.select_education_sub_type}
                  </MenuItem>
                  {educationsubTypedata.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                {errors.education_sub_id && (
                  <Typography variant="caption" color="error">
                    {errors.education_sub_id}
                  </Typography>
                )}

                <InputLabel
                  sx={{
                    fontSize: "small",
                    fontWeight: "bold",
                    marginTop: "14px",
                  }}
                >
                  {Strings.occupation}
                </InputLabel>
                <Select
                  fullWidth
                  placeholder="Select Occupation"
                  value={occupation}
                  onChange={(e) => {
                    handleChangeoccupation(e);
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      occupation: "",
                    }));
                  }}
                  style={{
                    color: "#AEAFAF",
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "#AEAFAF",
                    },
                    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                      borderBottomColor: "#AEAFAF",
                    },
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                  displayEmpty
                  defaultValue={{
                    value: isEdit ? location.state.rowData.occupation_id : 0,
                    label: isEdit
                      ? location.state.rowData.occupation_name
                      : Strings.select_occupation,
                  }}
                >
                  <MenuItem value="" disabled>
                    {Strings.select_occupation}
                  </MenuItem>
                  {occupationdata.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                {errors.occupation && (
                  <Typography variant="caption" color="error">
                    {errors.occupation}
                  </Typography>
                )}

                {/* Field 8: Designation */}
                <InputLabel
                  sx={{
                    fontSize: "small",
                    fontWeight: "bold",
                    marginTop: "14px",
                  }}
                >
                  {Strings.designation}
                </InputLabel>
                <TextField
                  fullWidth
                  placeholder={Strings.designation}
                  onChange={(e) => {
                    setdesignation(e.target.value);
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      designation: "",
                    }));
                  }}
                  value={designation}
                  defaultValue={
                    isEdit ? location.state.rowData.designation : ""
                  }
                  InputProps={{
                    style: {
                      color: "#888888",
                      fontWeight: 500,
                    },
                  }}
                />
                {errors.designation && (
                  <Typography variant="caption" color="error">
                    {errors.designation}
                  </Typography>
                )}

                {/* Field 9: Company/Department */}
                <InputLabel
                  sx={{
                    fontSize: "small",
                    fontWeight: "bold",
                    marginTop: "14px",
                  }}
                >
                  {Strings.company_dep}
                </InputLabel>
                <TextField
                  fullWidth
                  placeholder={Strings.company_dep}
                  onChange={(e) => {
                    setcompany_name(e.target.value);
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      company_name: "",
                    }));
                  }}
                  value={company_name}
                  defaultValue={
                    isEdit ? location.state.rowData.company_name : ""
                  }
                  InputProps={{
                    style: {
                      color: "#888888",
                      fontWeight: 500,
                    },
                  }}
                />
                {errors.company_name && (
                  <Typography variant="caption" color="error">
                    {errors.company_name}
                  </Typography>
                )}

                {/* Field 10: Occupation Address */}
                <InputLabel
                  sx={{
                    fontSize: "small",
                    fontWeight: "bold",
                    marginTop: "14px",
                  }}
                >
                  {Strings.occupation_address}
                </InputLabel>
                <TextField
                  fullWidth
                  placeholder={Strings.occupation_address}
                  onChange={(e) => {
                    setoccupation_address(e.target.value);
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      occupation_address: "",
                    }));
                  }}
                  InputProps={{
                    style: {
                      color: "#888888",
                      fontWeight: 500,
                    },
                  }}
                  value={occupation_address}
                  defaultValue={
                    isEdit ? location.state.rowData.occupation_address : ""
                  }
                />
                {errors.occupation_address && (
                  <Typography variant="caption" color="error">
                    {errors.occupation_address}
                  </Typography>
                )}

                {/* Field 11: Mother's Name */}
                <InputLabel
                  sx={{
                    fontSize: "small",
                    fontWeight: "bold",
                    marginTop: "14px",
                  }}
                >
                  {Strings.mothername}
                </InputLabel>
                <TextField
                  fullWidth
                  placeholder={Strings.mothername}
                  onChange={(e) => {
                    setmother_name(e.target.value);
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      mother_name: "",
                    }));
                  }}
                  InputProps={{
                    style: {
                      color: "#888888",
                      fontWeight: 500,
                    },
                  }}
                  value={mother_name}
                  defaultValue={
                    isEdit ? location.state.rowData.mother_name : ""
                  }
                />
                {errors.mother_name && (
                  <Typography variant="caption" color="error">
                    {errors.mother_name}
                  </Typography>
                )}

                {/* Field 12: Mosal Village */}
                <InputLabel
                  sx={{
                    fontSize: "small",
                    fontWeight: "bold",
                    marginTop: "14px",
                  }}
                >
                  {Strings.mosal_village}
                </InputLabel>
                <Select
                  fullWidth
                  placeholder={Strings.SelectMosalVillage}
                  value={mosal}
                  style={{
                    color: "#AEAFAF",
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "#AEAFAF",
                    },
                    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                      borderBottomColor: "#AEAFAF",
                    },
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                  onChange={(e) => {
                    handleChange(e);
                    setErrors((prevErrors) => ({ ...prevErrors, mosal: "" }));
                  }}
                  defaultValue={{
                    value: isEdit ? location.state.rowData.village_id : 0,
                    label: isEdit
                      ? location.state.rowData.mosal
                      : Strings.select_mosal_village,
                  }}
                >
                  <MenuItem value={0} disabled>
                    {Strings.select_mosal_village}
                  </MenuItem>
                  {mosaldata.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                  <MenuItem value="other">{Strings.Other}</MenuItem>
                </Select>
                {errors.mosal && (
                  <Typography variant="caption" color="error">
                    {errors.mosal}
                  </Typography>
                )}

                <Grid item xs={15}>
                  <InputLabel
                    sx={{
                      fontSize: "small",
                      fontWeight: "bold",
                      marginTop: "14px",
                    }}
                  >
                    {Strings.mama_name}
                  </InputLabel>
                  <TextField
                    fullWidth
                    placeholder={Strings.mama_name}
                    onChange={(e) => {
                      setm_uncle_name(e.target.value);
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        m_uncle_name: "",
                      }));
                    }}
                    value={m_uncle_name}
                    defaultValue={
                      isEdit ? location.state.rowData.m_uncle_name : ""
                    }
                    InputProps={{
                      style: {
                        color: "#888",
                      },
                    }}
                  />
                  {errors.m_uncle_name && (
                    <Typography variant="caption" color="error">
                      {errors.m_uncle_name}
                    </Typography>
                  )}
                </Grid>

                {/* Field 2: Blood Group */}
                <Grid item xs={15}>
                  <InputLabel
                    sx={{
                      fontSize: "small",
                      fontWeight: "bold",
                      marginTop: "14px",
                    }}
                  >
                    {Strings.blood_group}
                  </InputLabel>
                  <Select
                    fullWidth
                    placeholder={Strings.select_blood_group}
                    value={blood_group}
                    onChange={(e) => {
                      handleblood(e);
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        blood_group: "",
                      }));
                    }}
                    style={{
                      color: "#AEAFAF",
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#AEAFAF",
                      },
                      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                        borderBottomColor: "#AEAFAF",
                      },
                    }}
                    inputProps={{ "aria-label": "Without label" }}
                    displayEmpty
                    defaultValue={{
                      value: isEdit ? location.state.rowData.group_name : 0,
                      label: isEdit
                        ? location.state.rowData.blood_group
                        : Strings.select_blood_group,
                    }}
                  >
                    <MenuItem value={0} disabled>
                      {Strings.select_blood_group}
                    </MenuItem>
                    {blooddata.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.blood_group && (
                    <Typography variant="caption" color="error">
                      {errors.blood_group}
                    </Typography>
                  )}
                </Grid>

                {/* Blood Donate Interest Section */}
                <Grid item xs={15}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={blood_donate_status_checked}
                        onChange={handleChangeChk}
                      />
                    }
                    label={
                      <>
                        <Typography
                          fontWeight="regular"
                          sx={{
                            cursor: "pointer",
                            userSelect: "none",
                            color: "red",
                          }}
                        >
                          {Strings.blood_donate_interest}
                        </Typography>
                      </>
                    }
                  />
                </Grid>

                {/* Field 3: Current Country */}
                <Grid item xs={15}>
                  <InputLabel
                    sx={{
                      fontSize: "small",
                      fontWeight: "bold",
                      marginTop: "14px",
                    }}
                  >
                    {Strings.current_country}
                  </InputLabel>
                  <Select
                    fullWidth
                    placeholder={Strings.select_current_country}
                    value={country_id}
                    onChange={(e) => {
                      handelshowhide(e);
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        country_id: "",
                      }));
                    }}
                    style={{
                      color: "#AEAFAF",
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#AEAFAF",
                      },
                      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                        borderBottomColor: "#AEAFAF",
                      },
                    }}
                    inputProps={{ "aria-label": "Without label" }}
                    displayEmpty
                    defaultValue={{
                      value: isEdit ? location.state.rowData.country_id : 0,
                      label: isEdit
                        ? location.state.rowData.country_name
                        : Strings.select_current_country,
                    }}
                  >
                    <MenuItem value={0} disabled>
                      {Strings.select_current_country}
                    </MenuItem>
                    {countrydata.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.country_id && (
                    <Typography variant="caption" color="error">
                      {errors.country_id}
                    </Typography>
                  )}
                </Grid>

                {/* Visa Type Section */}
                {showvisa && (
                  <Grid item xs={15}>
                    <InputLabel
                      sx={{
                        fontSize: "small",
                        fontWeight: "bold",
                        marginTop: "14px",
                      }}
                    >
                      {Strings.visa_type}
                    </InputLabel>
                    <Select
                      fullWidth
                      placeholder={Strings.select_visa_type}
                      value={visa_type_name}
                      onChange={(e) => {
                        setvisa_type_name(e.target.value);
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          visa_type_name: "",
                        }));
                      }}
                      style={{
                        color: "#AEAFAF",
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "#AEAFAF",
                        },
                        "& .MuiInput-underline:hover:not(.Mui-disabled):before":
                          {
                            borderBottomColor: "#AEAFAF",
                          },
                      }}
                      inputProps={{ "aria-label": "Without label" }}
                      displayEmpty
                      defaultValue={{
                        value: isEdit ? location.state.rowData.visa_type_id : 0,
                        label: isEdit
                          ? location.state.rowData.visa_type_name
                          : Strings.select_visa_type,
                      }}
                    >
                      <MenuItem value={0} disabled>
                        {Strings.select_visa_type}
                      </MenuItem>
                      {visadata.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.visa_type_name && (
                      <Typography variant="caption" color="error">
                        {errors.visa_type_name}
                      </Typography>
                    )}
                  </Grid>
                )}

                {/* Field 4: Weight */}
                <Grid item xs={15}>
                  <InputLabel
                    sx={{
                      fontSize: "small",
                      fontWeight: "bold",
                      marginTop: "14px",
                    }}
                  >
                    {Strings.weight}
                  </InputLabel>
                  <Select
                    fullWidth
                    placeholder={Strings.select_weight}
                    value={weight}
                    onChange={(e) => {
                      handleChangeweight(e);
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        weight: "",
                      }));
                    }}
                    style={{
                      color: "#AEAFAF",
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#AEAFAF",
                      },
                      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                        borderBottomColor: "#AEAFAF",
                      },
                    }}
                    inputProps={{ "aria-label": "Without label" }}
                    displayEmpty
                    defaultValue={{
                      value: isEdit ? location.state.rowData.weight : 0,
                      label: isEdit
                        ? location.state.rowData.weight
                        : Strings.select_weight,
                    }}
                  >
                    <MenuItem value={0} disabled>
                      {Strings.select_weight}
                    </MenuItem>
                    {options.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.weight && (
                    <Typography variant="caption" color="error">
                      {errors.weight}
                    </Typography>
                  )}
                </Grid>

                {/* Field 5: Height Foot */}
                <Grid item xs={15}>
                  <InputLabel
                    sx={{
                      fontSize: "small",
                      fontWeight: "bold",
                      marginTop: "14px",
                    }}
                  >
                    {Strings.height_foot}
                  </InputLabel>
                  <Select
                    fullWidth
                    placeholder={Strings.height_foot}
                    value={height}
                    onChange={(e) => {
                      handlehieght(e);
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        height: "",
                      }));
                    }}
                    style={{
                      color: "#AEAFAF",
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#AEAFAF",
                      },
                      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                        borderBottomColor: "#AEAFAF",
                      },
                    }}
                    inputProps={{ "aria-label": "Without label" }}
                    displayEmpty
                    defaultValue={{
                      value: isEdit ? height : 0,
                      label: isEdit ? height : Strings.height_foot,
                    }}
                  >
                    <MenuItem value={0} disabled>
                      {Strings.height_foot}
                    </MenuItem>
                    {optionsfoot.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.height && (
                    <Typography variant="caption" color="error">
                      {errors.height}
                    </Typography>
                  )}
                </Grid>

                {/* Field 6: Height Inches */}
                <Grid item xs={15}>
                  <InputLabel
                    sx={{
                      fontSize: "small",
                      fontWeight: "bold",
                      marginTop: "14px",
                    }}
                  >
                    {Strings.height_inches}
                  </InputLabel>
                  <Select
                    fullWidth
                    placeholder={Strings.height_inches}
                    value={heightinch}
                    onChange={(e) => {
                      handleChangeinch(e);
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        heightinch: "",
                      }));
                    }}
                    style={{
                      color: "#AEAFAF",
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#AEAFAF",
                      },
                      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                        borderBottomColor: "#AEAFAF",
                      },
                    }}
                    inputProps={{ "aria-label": "Without label" }}
                    displayEmpty
                    defaultValue={{
                      value: isEdit ? heightinch : 0,
                      label: isEdit ? heightinch : Strings.height_inches,
                    }}
                  >
                    <MenuItem value={0} disabled>
                      {Strings.height_inches}
                    </MenuItem>
                    {options1.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.heightinch && (
                    <Typography variant="caption" color="error">
                      {errors.heightinch}
                    </Typography>
                  )}
                </Grid>

                {/* Field 7: Annual Income */}
                {isChecked &&
                  marital_status != 2 &&
                  marital_status != 4 &&
                  marital_status != 5 &&
                  marital_status != 8 && (
                    <Grid item xs={15}>
                      <InputLabel
                        sx={{
                          fontSize: "small",
                          fontWeight: "bold",
                          marginTop: "14px",
                        }}
                      >
                        {Strings.annual_income}
                      </InputLabel>
                      <TextField
                        fullWidth
                        placeholder={Strings.annual_income}
                        onChange={(e) => {
                          setannual_income(e.target.value);
                        }}
                        value={annual_income}
                        defaultValue={
                          isEdit ? location.state.rowData.annual_income : ""
                        }
                        InputProps={{
                          style: {
                            color: "#888",
                          },
                        }}
                      />
                    </Grid>
                  )}

                {/* Field 8: About Me */}
                <Grid item xs={15}>
                  <InputLabel
                    sx={{
                      fontSize: "small",
                      fontWeight: "bold",
                      marginTop: "14px",
                    }}
                  >
                    {Strings.about_me}
                  </InputLabel>
                  <TextField
                    fullWidth
                    placeholder={Strings.about_me}
                    onChange={(e) => {
                      setabout_me(e.target.value);
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        about_me: "",
                      }));
                    }}
                    InputProps={{
                      style: {
                        color: "#888",
                      },
                    }}
                    value={about_me}
                    defaultValue={isEdit ? location.state.rowData.about_me : ""}
                  />
                  {errors.about_me && (
                    <Typography variant="caption" color="error">
                      {errors.about_me}
                    </Typography>
                  )}
                </Grid>
              </div>
            </div>
          </Grid>
        </Grid>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <div style={{ padding: "8px 0", textAlign: "center" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting
                ? Strings.Adding
                : location && location.state && location.state.rowData
                ? Strings.updatemember
                : Strings.add_member}
            </Button>
          </div>
        </div>
      </Box>
    </div>
  );
}
