import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  CssBaseline,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Sidebar from "./Sidebar";
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import "../styles/Sidebar.scss";
import { Strings } from "../Common/String";
import '../theme/colors.scss';

const drawerWidth = 240;

const Footer = () => {
  const handleAnthesisClick = () => {
    window.open("https://anthesis.in/", "_blank");
  };
  return (
    <footer
      style={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        height: "50px",
        background: '#FAF9F6',
        color: "#333333",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxShadow: "0px -5px 15px rgba(0, 0, 0, 0.1)",
        zIndex: 10,
      }}
    >
     <Typography variant="body2">
        &copy; {new Date().getFullYear()} | Rami Samaj All Rights Reserved. Developed By{" "}
        <strong>
          <a
            href="https://anthesis.in/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", color: "#333333" }}
            onClick={(e) => {
              e.preventDefault();
              handleAnthesisClick();
            }}
          >
            Anthesis
          </a>
        </strong>
      </Typography>
    </footer>
  );
};

const MainLayout = ({ children }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down("md"));
  const condition = localStorage.getItem("approve_status");

  React.useEffect(() => {
    if (isTabletOrMobile) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [isTabletOrMobile]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  return (
    <Box>
      <CssBaseline />
      <AppBar
        position="fixed"
        className="mainscreen"
        sx={{
          // zIndex: theme.zIndex.drawer + 1,
          transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          ...(open && {
            marginLeft: drawerWidth,
            overflowY: 'visible',
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(["width", "margin"], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
          }),
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            {Strings.msg_guj}
          </Typography>

          <IconButton
        color="inherit"
        aria-label="logout"
        edge="end"
        sx={{ ml: 'auto' }}
        onClick={handleLogout}
      >
        <PowerSettingsNewIcon />
      </IconButton>
        </Toolbar>
      </AppBar>
      <Sidebar
        open={open}
        handleDrawerClose={handleDrawerClose}
        condition={condition}
      />
     <Box
        component="main"
        sx={{
          flexGrow: 1,
          padding: theme.spacing(3),
          marginLeft: open ? `${drawerWidth}px` : 0,
          transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
          width: open ? `calc(100% - ${drawerWidth}px)` : "100%",
        }}
      >
        <div style={theme.mixins.toolbar} />
        {children}
      </Box>
      <Footer />
    </Box>
  );
};

export default MainLayout;
