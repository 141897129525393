import React, { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  Typography,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Avatar,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { ViewBhagini_SansthasAPI } from "../Features/services";
import { Imageurl } from "../Features/apisUrl";
import { Strings } from "../Common/String";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import nodatafound from "../assets/background/nodatafound.png";
import PersonIcon from '@mui/icons-material/Person';

function CommitteeMemberList() {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [offset, setOffset] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ViewBhagini_SansthasAPI({
          committee_pragatimandal_id:
            location.state.data.committee_pragatimandal_id,
        });
        if (response.ok === true) {
          setData(response.data || []);
        } else {
          setData([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setData([]);
      }
    };

    fetchData();
  }, [offset, location.state.data.committee_pragatimandal_id]);

  // const handleRowClick = (selectedData) => {
  //   navigate(`/committeelist`, {
  //     state: { data: selectedData },
  //   });
  // };

  const handleImageClick = (index, data) => {
    if(data?.image) {
      setSelectedImage(data?.image);
      setOpen(true);
    }
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const defaultProfileImage = <PersonIcon style={{ fontSize: 30}} />;

  return (
    <Card style={{ marginBottom: "30px" }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={3}
      >
        <Box style={{ marginTop: "2%", display: "flex", alignItems: "center" }}>
          <IconButton onClick={handleBack}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6">{Strings.CommitteeMemberList}</Typography>
        </Box>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{Strings.Image}</TableCell>
              <TableCell>{Strings.Name}</TableCell>
              <TableCell>{Strings.Village}</TableCell>
              <TableCell>{Strings.CommitteeName}</TableCell>
              <TableCell>{Strings.Contact}</TableCell>
              <TableCell>{Strings.Designation}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((val, index) => (
              <TableRow
                key={val.family_member_id}
                style={{ cursor: "pointer" }}
              >
                <TableCell>
                  <Avatar
                    src={Imageurl.committee_members_image + val.image}
                    alt={val.first_name}
                    sx={{
                      cursor: "pointer",
                      "&:hover, &:focus": {
                        zIndex: "10",
                      },
                    }}
                    onClick={() => handleImageClick(index, val)}
                  >
                    {defaultProfileImage}
                  </Avatar>
                </TableCell>
                <TableCell>{val.name == "" || val.name == null ? "-" : val.name}</TableCell>
                <TableCell>{val.village_name == "" || val.village_name == null ? "-" : val.village_name}</TableCell>
                <TableCell>{val.committee_pragatimandal_name == "" || val.committee_pragatimandal_name == null ? "-" : val.committee_pragatimandal_name}</TableCell>
                <TableCell>
                  {val.gender === "female" ? "-" : val.contact == "" || val.contact == null ? "-" : val.contact}
                </TableCell>
                <TableCell>{val.designation_name == "" || val.designation_name == null ? "-" : val.designation_name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {data.length === 0 && (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          p={3}
        >
          <img
            src={nodatafound}
            alt="No Data Found"
            style={{ width: "500px", height: "auto" }}
          />
        </Box>
      )}
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Dialog open={open} onClose={handleCloseModal}>
        <DialogContent>
          <img
            src={Imageurl.committee_members_image + selectedImage}
            alt="Member Photo"
            style={{ width: "100%", height: "auto" }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}

export default CommitteeMemberList;
