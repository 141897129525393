import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Card, Typography, Box, Grid, IconButton, Button, Avatar } from "@mui/material";
import {
  Facebook as FacebookIcon,
  YouTube as YouTubeIcon,
  Instagram as InstagramIcon,
  Telegram as TelegramIcon,
  LinkedIn as LinkedInIcon,
  Description as DescriptionIcon,
  Call as CallIcon,
  BusinessCenter as BusinessCenterIcon,
  Email as EmailIcon,
  MenuBook as MenuBookIcon,
  EmailOutlined,
  DescriptionOutlined,
  PersonOutlineOutlined,
  LocationOnOutlined,
  LocalHospitalOutlined,
  WorkOutlineOutlined,
  BusinessOutlined,
  PublicOutlined,
  FitnessCenterOutlined,
  MonetizationOnOutlined,
  FavoriteOutlined,
  SubjectOutlined,
  PinDropIcon,
  LanguageIcon,
  EventOutlined,
  ChildFriendlyOutlined,
  SchoolOutlined,
  LocationCityOutlined,
  CardMembershipOutlined,
  FamilyRestroomOutlined,
  FaceOutlined,
  FavoriteBorderOutlined,
  AccessibilityNewOutlined,
} from "@mui/icons-material";
import { Imageurl } from "../Features/apisUrl";
import { Strings } from "../Common/String";
import { useEffect, useState } from "react";
import { ProfilepageyAPI } from "../Features/services";
import BloodtypeOutlined from "@mui/icons-material/BloodtypeOutlined";
import PersonIcon from '@mui/icons-material/Person';

function Profile() {
  const location = useLocation();
  const navigate = useNavigate();
  const businessDetails = location.state?.data || null;

  const [userdata, setuserdata] = useState([]);

  useEffect(() => {
    if (localStorage.getItem("token") !== null) {
      let data = JSON.parse(localStorage.getItem("token"));
    }
    (async () => {
      const profileresponse = await ProfilepageyAPI(
        localStorage.getItem("family_member_id")
      );
      if (profileresponse) {
        setuserdata(profileresponse[0]);
      }
    })();
  }, []);

  const getIconColor = (category) => {
    switch (category) {
      case Strings.category:
        return "#ff9800"; // Orange
      case Strings.description:
        return "#4caf50"; // Green
      case Strings.address:
        return "#2196f3"; // Blue
      case Strings.city:
        return "#f44336"; // Red
      case Strings.PIN:
        return "#9c27b0"; // Purple
      case Strings.country:
        return "#673ab7"; // Deep Purple
      case Strings.contact_number1:
        return "#e91e63"; // Pink
      case Strings.contact_number2:
        return "#00bcd4"; // Cyan
      case Strings.website:
        return "#009688"; // Teal
      case Strings.email:
        return "#ff5722"; // Deep Orange
      case Strings.business_catalogue:
        return "#795548"; // Brown
      default:
        return "inherit"; // Default color
    }
  };

  const handleBack = () => {
    navigate("/marriage");
  };

  const handleClick = (rowData) => {
    navigate("/memberregistration", { state: { rowData } });
  };

  const defaultProfileImage = <PersonIcon style={{ fontSize: 30}} />;
  const formatDate = (dateString) => {
    if (dateString) {
      const [year, month, day] = dateString?.split("-");
      return `${day}-${month}-${year}`;
    }
  };

  return (
    <>
      <Box style={{ marginTop: "2%", display: "flex", alignItems: "center" }}>
        {/* <IconButton onClick={handleBack}>
          <ArrowBackIcon />
        </IconButton> */}
        <Typography style={{ fontWeight: 500, fontSize: "22px" }}>
          {Strings.Profile}
        </Typography>
      </Box>
      <Card style={{ marginTop: "3%", marginBottom: "35px" }}>
        <Box p={2}>
          {/* {businessDetails ? ( */}
          <>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={4} sx={{ marginTop: "3%" }}>
                <Box display="flex" justifyContent="center">
                  <Avatar
                    src={Imageurl.member_image + userdata?.photo}
                    alt={userdata.first_name}
                    sx={{
                      cursor: "pointer",
                      "&:hover, &:focus": {
                        zIndex: "10",
                      },
                      width: "200px",
                      height: "200px",
                      objectFit: "cover",
                      borderRadius: "50%",
                      maxWidth: "100%",
                      maxHeight: "100%",
                    }}
                  >
                    {defaultProfileImage}
                  </Avatar>
                </Box>
                <Box mt={2}>
                  <Typography
                    variant="h5"
                    align="center"
                    style={{ fontWeight: 500 }}
                  >
                    {userdata ? userdata?.surname == "" || userdata?.surname == null ? '-' : userdata?.surname +
                      " " +
                      userdata?.first_name == "" || userdata?.first_name == null ? '-' : userdata?.first_name +
                      " " +
                      userdata?.last_name == "" || userdata?.last_name == null ? '-' : userdata?.last_name +
                      " " +
                      userdata?.middle_name == "" || userdata?.middle_name == null ? '-' : userdata?.middle_name : ""}
                  </Typography>
                </Box>
                <Box
                  mt={2}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    align="center"
                    style={{ fontWeight: 500 }}
                  >
                    {userdata?.relation_name ? userdata?.relation_name : "-"}
                  </Typography>
                </Box>
                <Box
                  mt={2}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    style={{ backgroundColor: "#4682B4", color: "white" }}
                    onClick={() => handleClick(userdata)}
                  >
                    {Strings.EDIT}
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4} mt={5}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item sx={{ marginTop: '5px' }}>
                        <EmailOutlined style={{ color: "maroon" }} />
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1">
                          <strong>{Strings.email}</strong>
                        </Typography>
                        <Typography variant="body2">
                          {userdata?.email ? userdata?.email : "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item sx={{ marginTop: '5px' }}>
                        <CallIcon
                          style={{ color: getIconColor(Strings.description) }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1">
                          <strong>{Strings.contact}</strong>
                        </Typography>
                        <Typography variant="body2">
                          {userdata?.gender == "female"
                            ? "-"
                            : userdata?.contact ? userdata?.contact : "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item sx={{ marginTop: '5px' }}>
                        <PersonOutlineOutlined style={{ color: "gray" }} />
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1">
                          <strong>{Strings.gender}</strong>
                        </Typography>
                        <Typography variant="body2">
                          {userdata?.gender == "female (??????)" ? "female" : userdata?.gender == "male (?????)" ? "male" : "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item sx={{ marginTop: '5px' }}>
                        <LocationOnOutlined style={{ color: "red" }} />
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1">
                          <strong>{Strings.village}</strong>
                        </Typography>
                        <Typography variant="body2">
                          {userdata?.village_name ? userdata?.village_name : "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item sx={{ marginTop: '5px' }}>
                        <LocalHospitalOutlined style={{ color: "maroon" }} />
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1">
                          <strong>{Strings.blood_group}</strong>
                        </Typography>
                        <Typography variant="body2">
                          {userdata?.blood_group ? userdata?.blood_group : "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item sx={{ marginTop: '5px' }}>
                        <BloodtypeOutlined style={{ color: "darkred" }} />
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1">
                          <strong>{Strings.InterestBloodDonation}</strong>
                        </Typography>
                        <Typography variant="body2">
                          {userdata?.blood_donate_status == ""
                            ? "No"
                            : userdata?.blood_donate_status ? userdata?.blood_donate_status == 1 ? "Yes" : "No" : "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item sx={{ marginTop: '5px' }}>
                        <WorkOutlineOutlined
                          style={{ color: getIconColor(Strings.address) }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1">
                          <strong>{Strings.occupation}</strong>
                        </Typography>
                        <Typography variant="body2">
                          {userdata?.occupation_name ? userdata?.occupation_name : "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item sx={{ marginTop: '5px' }}>
                        <BusinessOutlined style={{ color: "darkgray" }} />
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1">
                          <strong>{Strings.company_name}</strong>
                        </Typography>
                        <Typography variant="body2">
                          {userdata?.company_name ? userdata?.company_name : "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item sx={{ marginTop: '5px' }}>
                        <PublicOutlined style={{ color: "darkslateblue" }} />
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1">
                          <strong>{Strings.designation}</strong>
                        </Typography>
                        <Typography variant="body2">
                          {userdata?.designation ? userdata?.designation : "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item sx={{ marginTop: '5px' }}>
                        <WorkOutlineOutlined style={{ color: "green" }} />
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1">
                          <strong>{Strings.occupation_address}</strong>
                        </Typography>
                        <Typography variant="body2">
                          {userdata?.occupation_address ? userdata?.occupation_address : "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item sx={{ marginTop: '5px' }}>
                        <FitnessCenterOutlined
                          style={{ color: "saddlebrown" }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1">
                          <strong>{Strings.weight}</strong>
                        </Typography>
                        <Typography variant="body2">
                          {userdata?.weight ? userdata?.weight : "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {userdata?.annual_income != "" &&
                  userdata?.marital_status != "2" &&
                  userdata?.marital_status != "4" &&
                  userdata?.marital_status != "5" &&
                  userdata?.marital_status != "8" ? (
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item sx={{ marginTop: '5px' }}>
                          <MonetizationOnOutlined
                            style={{ color: "darkgoldenrod" }}
                          />
                        </Grid>
                        <Grid item>
                          <Typography variant="subtitle1">
                            <strong>{Strings.annual_income}</strong>
                          </Typography>
                          <Typography variant="body2">
                            {userdata?.annual_income}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    ""
                  )}
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item sx={{ marginTop: "5px" }}>
                        <FavoriteOutlined style={{ color: "rosybrown" }} />
                      </Grid>
                      <Grid item xs>
                        <Typography
                          variant="subtitle1"
                          style={{ overflowWrap: "anywhere" }}
                        >
                          <strong>{Strings.matrimonial_interest}</strong>
                        </Typography>
                        <Typography
                          variant="body2"
                          style={{ overflowWrap: "anywhere" }}
                        >
                          {userdata?.matrimonial_status === "2" ? "Yes" : "No"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item sx={{ marginTop: "5px" }}>
                        <SubjectOutlined
                          style={{ color: getIconColor(Strings.country) }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1">
                          <strong>{Strings.about_me}</strong>
                        </Typography>
                        <Typography variant="body2">
                          {userdata?.about_me ? userdata?.about_me : "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4} mt={5}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item sx={{ marginTop: '5px' }}>
                        <EventOutlined style={{ color: "cadetblue" }} />
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1">
                          <strong>{Strings.dob}</strong>
                        </Typography>
                        <Typography variant="body2">
                          {userdata?.birthdate ? formatDate(userdata?.birthdate) : "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item sx={{ marginTop: '5px' }}>
                        <ChildFriendlyOutlined style={{ color: "navy" }} />
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1">
                          <strong>{Strings.age_colon}</strong>
                        </Typography>
                        <Typography variant="body2">{userdata?.age ? userdata?.age : "-"}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item sx={{ marginTop: '5px' }}>
                        <SchoolOutlined style={{ color: "darkcyan" }} />
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1">
                          <strong>{Strings.education_name}</strong>
                        </Typography>
                        <Typography variant="body2">
                          {userdata?.education_name ? userdata?.education_name +
                            "/" +
                            userdata?.education_sub_name : "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item sx={{ marginTop: '5px' }}>
                        <LocationOnOutlined style={{ color: "darkkhaki" }} />
                      </Grid>
                      <Grid item xs>
                        <Typography variant="subtitle1" style={{ overflowWrap: "anywhere" }}>
                          <strong>{Strings.residence_address}</strong>
                        </Typography>
                        <Typography variant="body2" style={{ overflowWrap: "anywhere" }}>
                          {userdata?.residence_address ? userdata?.residence_address : "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item sx={{ marginTop: '5px' }}>
                        <LocationCityOutlined
                          style={{ color: "darkmagenta" }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1">
                          <strong>{Strings.city_location}</strong>
                        </Typography>
                        <Typography variant="body2">
                          {userdata?.contact_2 ? userdata?.contact_2 : "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item sx={{ marginTop: '5px' }}>
                        <PublicOutlined style={{ color: "darkolivegreen" }} />
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1">
                          <strong>{Strings.current_country}</strong>
                        </Typography>
                        <Typography variant="body2">
                          {userdata?.country_name ? userdata?.country_name : "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item sx={{ marginTop: '5px' }}>
                        <CardMembershipOutlined
                          style={{ color: "darkturquoise" }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1">
                          <strong>{Strings.visa_type}</strong>
                        </Typography>
                        <Typography variant="body2">
                          {userdata?.visa_type_name ? userdata?.visa_type_name : "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item sx={{ marginTop: '5px' }}>
                        <FamilyRestroomOutlined
                          style={{ color: "lightsteelblue" }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1">
                          <strong>{Strings.mosal}</strong>
                        </Typography>
                        <Typography variant="body2">
                          {userdata?.mosal ? userdata?.mosal : "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item sx={{ marginTop: '5px' }}>
                        <FaceOutlined style={{ color: "lawngreen" }} />
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1">
                          <strong>{Strings.mothername}</strong>
                        </Typography>
                        <Typography variant="body2">
                          {userdata?.mother_name ? userdata?.mother_name : "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item sx={{ marginTop: '5px' }}>
                        <FaceOutlined style={{ color: "darkviolet" }} />
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1">
                          <strong>{Strings.mama_name}</strong>
                        </Typography>
                        <Typography variant="body2">
                          {userdata?.m_uncle_name ? userdata?.m_uncle_name : "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item sx={{ marginTop: '5px' }}>
                        <FavoriteBorderOutlined style={{ color: "darkred" }} />
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1">
                          <strong>{Strings.marital_status}</strong>
                        </Typography>
                        <Typography variant="body2">
                          {userdata?.marital_status == "1"
                            ? Strings.Single
                            : "" || userdata?.marital_status == "2"
                            ? Strings.Married
                            : "" || userdata?.marital_status == "3"
                            ? Strings.Widowed
                            : "" || userdata?.marital_status == "4"
                            ? Strings.Engaged
                            : "" || userdata?.marital_status == "5"
                            ? Strings.Separated
                            : "" || userdata?.marital_status == "6"
                            ? Strings.Divorced
                            : "" || userdata?.marital_status == "7"
                            ? Strings.Widower
                            : "" || userdata?.marital_status == "8"
                            ? Strings.NotInterested
                            : ""}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item sx={{ marginTop: '5px' }}>
                        <AccessibilityNewOutlined
                          style={{ color: "lightsteelblue" }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1">
                          <strong>{Strings.height}</strong>
                        </Typography>
                        <Typography variant="body2">
                          {userdata?.height ? userdata?.height : "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        </Box>
      </Card>
    </>
  );
}

export default Profile;
